
import { EXPANSION_DELAY_TIME } from '@/modules/constants';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ExpansionItem extends Vue {
    @Prop({ default: false }) private multipleLinesHeader!: boolean;

    @Prop({ default: '' }) private icon!: string;

    @Prop({ default: '' }) private id!: string;

    @Prop({ default: '0' }) private tabIndex!: string;

    @Prop() private openIndividual!: boolean;

    @Prop() private value!: boolean;

    get elementClasses() {
        return [
            'ExpansionItem',
            { 'ExpansionItem--expanded': this.value },
            { 'ExpansionItem--multipleLines': this.multipleLinesHeader },
            { 'ExpansionItem--withIcon': this.icon },
        ];
    }

    get expansionItemContentClasses() {
        return [
            'ExpansionItem__Content',
            { 'ExpansionItem__Content--expanded': this.value },
        ];
    }

    get labelForAria() {
        return this.$t(this.value ? 'areaLabel.expansionItem.expandLess' : 'areaLabel.expansionItem.expandMore');
    }

    toggleContent() {
        if (this.value) {
            (this.$refs.expansionContent as HTMLElement).classList.add('ExpansionItem__Content--closedScale');
            setTimeout(() => {
                this.$emit('input', !this.value);
                (this.$refs.expansionContent as HTMLElement).classList.add('ExpansionItem__Content--closedHeight');
            }, EXPANSION_DELAY_TIME);
        } else {
            this.$emit('input', !this.value);
        }
    }
}
