
import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Achievement from '@/projectComponents/achievement/index.vue';
import resolveStatus from '@/projectComponents/achievement/resolveStatus';
import descriptionResolver from '@/modules/NewStarters/services/descriptionResolver';
import dateManager from '@/utils/time';
import env from '@/environment';
import mockedDateManager from '@/utils/mocked-date-manager';

@Component({
    components: {
        Achievement,
    },
})
export default class NewStartersContentQuickStartMilestones extends Vue {
    @Prop() private isMobile!: boolean;

    @Prop() private milestone!: MilestoneType;

    @Prop() private labels!: {};

    get status(): string {
        return resolveStatus(this.milestone);
    }

    get description(): string {
        return descriptionResolver(this.milestone.key, this.$t);
    }

    get daysLeft(): string {
        let difference = dateManager
            .getDifference(
                this.mockedData ? mockedDateManager.getCurrentDate() : dateManager.getCurrentDate(),
                this.milestone.endDate,
                'day',
            );

        if (difference < 0) {
            difference = 0;
        }

        return `${this.$t('common.dayLeftLower', { count: difference })}`;
    }

    get mockedData(): boolean {
        return env.VUE_APP_MOCK_GRAPHQL === 'true';
    }
}
