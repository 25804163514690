<template>
    <UILayout
        title="Icon buttons"
        route="uiComponentsIconButtons"
        id="iconButtonsScene"
    >
        <div class="Ui__Card pts-layout pts-gutter">
            <div class="pts-layout-item pts-size-100">
                <div>
                    <h4>Icon buttons size medium</h4>
                    <div class="pts-layout-item pts-layout pts-gutter">
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="contained"
                                icon="category"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="outlined"
                                icon="category"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="text"
                                icon="category"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                :disabled="true"
                                icon="category"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                :disabled="true"
                                type="text"
                                icon="category"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                :disabled="true"
                                type="outlined"
                                icon="category"
                            />
                        </div>
                    </div>
                    <br />
                    <h4>Icon buttons size large</h4>
                    <div class="pts-layout-item pts-layout pts-gutter">
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="contained"
                                icon="category"
                                size="large"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="outlined"
                                icon="category"
                                size="large"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="text"
                                icon="category"
                                size="large"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                :disabled="true"
                                icon="category"
                                size="large"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                :disabled="true"
                                type="text"
                                icon="category"
                                size="large"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                :disabled="true"
                                type="outlined"
                                icon="category"
                                size="large"
                            />
                        </div>
                    </div>
                    <br />
                    <h4>Icon buttons with various size icons</h4>
                    <div class="pts-layout-item pts-layout pts-gutter">
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="contained"
                                icon="category"
                                size="large"
                                iconSize="small"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="contained"
                                icon="category"
                                size="large"
                                iconSize="medium"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="contained"
                                icon="category"
                                size="large"
                                iconSize="large"
                            />
                        </div>
                    </div>
                    <br />
                    <h4>Inverted</h4>
                    <div class="UIIconButtons__InvertedContainer">
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="contained"
                                icon="category"
                                :invertedColors="true"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="outlined"
                                icon="category"
                                :invertedColors="true"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="text"
                                icon="category"
                                :invertedColors="true"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                :disabled="true"
                                icon="category"
                                :invertedColors="true"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                :disabled="true"
                                type="text"
                                icon="category"
                                :invertedColors="true"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                :disabled="true"
                                type="outlined"
                                icon="category"
                                :invertedColors="true"
                            />
                        </div>
                    </div>
                    <h4>Light theme</h4>
                    <div class="UIIconButtons__InvertedContainer">
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="contained"
                                icon="category"
                                :lightTheme="true"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="outlined"
                                icon="category"
                                :lightTheme="true"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                type="text"
                                icon="category"
                                :lightTheme="true"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                :disabled="true"
                                icon="category"
                                :lightTheme="true"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                :disabled="true"
                                type="text"
                                icon="category"
                                :lightTheme="true"
                            />
                        </div>
                        <div class="pts-layout-item pts-size-10 pts-small-size-25">
                            <IconButton
                                :disabled="true"
                                type="outlined"
                                icon="category"
                                :lightTheme="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </UILayout>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UIIconButtons extends Vue {}
</script>

<style lang="scss">
    .UIIconButtons__InvertedContainer {
        background: $backgroundColor;
        display: flex;
        width: 100%;
        margin-top: 8px;
        padding: 8px;
    }
</style>
