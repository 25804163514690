import {
    Module, VuexModule, Mutation, getModule,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import componentsControls from '@/modules/ComponentsControls';
import { RouteType } from './types';
import { routerParentsObjectProcessor, routerInstancesRemover } from './helpers';

@Module({
    namespaced: true, dynamic: true, store, name: 'system',
})
@AutoMutations
export class System extends VuexModule {
    route: RouteType|null = null;

    screenType: string = '';

    screenWidth: number = 0;

    screenHeight: number = 0;

    @Mutation
    public setRoute(val: any) {
        const { matched, ...valData } = val;

        const matchedList = matched.map((item) => {
            let itemData = routerInstancesRemover(item);

            itemData = routerParentsObjectProcessor(itemData);

            return itemData;
        });

        this.route = { ...valData, matched: [...matchedList] };
    }

    @Mutation
    public setScreenWidth(val: number) {
        this.screenWidth = val;
    }

    @Mutation
    public setScreenHeight(val: number) {
        this.screenHeight = val;
    }

    @Mutation
    public setScreenType(val: string) {
        this.screenType = val;
    }

    @Action()
    public handleScreenChange(screen: HTMLElement) {
        const { offsetWidth, offsetHeight } = screen;
        this.setScreenWidth(offsetWidth);
        this.setScreenHeight(offsetHeight);

        if (offsetWidth < 600) {
            this.setScreenType('mobile');
        } else if (offsetWidth < 1280) {
            this.setScreenType('tablet');
        } else {
            this.setScreenType('desktop');
        }
    }

    @Action()
    public handleNetworkError() {
        // @ts-ignore
        componentsControls.showErrorMessage({ message: this.store._vm.$t('message.network.networkError'), time: 0 });
    }

    @Action()
    public handleConnectionReestablish() {
        // @ts-ignore
        componentsControls.showNextMessage({ message: this.store._vm.$t('message.network.connectionReestablished'), type: 'success' });
    }
}

export default getModule(System);
