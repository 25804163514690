
import Vue from 'vue';
import Component from 'vue-class-component';
import { Get } from '@/utils/vuex-module-mutators';
import authModule from '@/modules/Auth/index';

@Component
export default class AuthError extends Vue {
    @Get(authModule) authFailMessage!: string;

    @Get(authModule) authFailMode!: string;

    @Get(authModule) authFailContacts!: Array<string>;

    get errorText() {
        const wrappedContacts = this.authFailContacts.map(
            (contact) => `<a href="mailto:${contact}">${contact}</a>`,
        );
        if (this.authFailMode === 'tenant') {
            return this.$t('view.auth.tenant', {
                authFailMessage: this.authFailMessage,
                wrappedContacts: wrappedContacts.join(', '),
            });
        } if (this.authFailMode === 'accountDisabled') {
            return this.$t('view.auth.accountDisabled', {
                authFailMessage: this.authFailMessage,
                wrappedContacts: wrappedContacts.join(', '),
            });
        }
        return this.$t('common.accessDenied');
    }
}
