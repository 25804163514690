
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import system from '@/modules/System';
import { RouteType } from '@/views/Navigation/Item/types';
import bgImageResolver from '@/utils/bg-image-resolver';
import auth from '@/modules/Auth';
import settings from '@/modules/Settings';
import { SelectOptionsType } from '../select/types';

@Component
export default class SubScene extends Vue {
    @Prop({ required: true }) private route!: string;

    @Prop({ default: false }) private backButton!: boolean;

    @Prop({ default: false }) private withoutPadding!: boolean;

    @Prop() private title!: string;

    @Get(system, 'route') private currentRoute!: any;

    @Get(system) screenType!: string;

    @Get(settings) private lang!: string;

    @Prop() private backRoute!: RouteType;

    get elementClasses() {
        return [
            'SubScene__Container',
            { 'SubScene__Container--open': this.matchingRoute },
        ];
    }

    get elementContentClasses() {
        return [
            'SubScene__Content',
            { 'SubScene__Content--noPadding': this.withoutPadding },
        ];
    }

    get matchingRoute() {
        // @ts-ignore
        const { name, path } = this.currentRoute;

        if (this.route === name || this.route === path || this.matchPath()) {
            return true;
        }
        return false;
    }

    get bgImage(): string {
        return bgImageResolver(this.screenType);
    }

    get contentStyle() {
        return {
            // backgroundImage: `url('/img/becomeAnAmbassador/${this.bgImage}')`,
        };
    }

    get isHostess() {
        return auth.isHostess;
    }

    get langOptions(): SelectOptionsType[] {
        return settings.langOptions;
    }

    @Watch('$route', { immediate: true, deep: true })
    handleOpenRoute() {
        if (this.matchingRoute) {
            this.$emit('onEnter');
        }
    }

    handleBackButton() {
        this.$emit('onBackButton');
        this.handleClose();
    }

    handleRewardButton() {
        this.$router.push({ name: 'dashboard' });
    }

    handleClose() {
        this.$emit('onClose');
        if (!this.backRoute) {
            this.$router.go(-1);
        } else {
            this.$router.push(this.backRoute);
        }
    }

    matchPath() {
        return this.currentRoute.matched.find((item) => item.path === this.route);
    }

    setLang(val: string) {
        settings.setLang(val);
    }
}
