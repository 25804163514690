
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class NavSection extends Vue {
    @Prop({ required: true }) private title!: string;

    @Prop({ default: false }) private displayTooltip!: boolean;

    @Prop({ default: '' }) private tooltipText!: string;

    get classes() {
        return [
            'Navigation__LinksSection',
            this.childrenCount === 0 ? 'Navigation__LinksSection--hidden' : '',
        ];
    }

    get titleClasses() {
        return [
            'Navigation__LinkCategory--title',
            this.displayTooltip ? 'Navigation__LinkCategory--withTooltip' : '',
        ];
    }

    get childrenCount() {
        if (this.$slots.default) {
            return this.$slots.default!.length;
        }

        return 0;
    }

    get tooltipElementId(): string {
        return `nav-section-tooltip_${this.title.replace(' ', '-').toLowerCase()}`;
    }
}
