import { ResolverProductType } from '../types';

const products: ResolverProductType[] = [
    {
        id: '1',
        referenceNumber: 'AS-1',
        title: 'Schwarzer Kapuzenpullover',
        price: 25.98,
    },
    {
        id: '2',
        referenceNumber: 'AS-2',
        title: 'Weißer Kapuzenpullover',
        price: 17.55,
    },
    {
        id: '3',
        referenceNumber: 'AS-3',
        title: 'Roter Kapuzenpullover',
        price: 399.99,
    },
    {
        id: '4',
        referenceNumber: 'AS-4',
        title: 'Pflaumenbaum schwarzer Becher',
        price: 30.89,
    },
    {
        id: '5',
        referenceNumber: 'AS-5',
        title: 'Pflaumenbaum-weiße Tasse',
        price: 21.04,
    },
    {
        id: '6',
        referenceNumber: 'AS-6',
        title: 'Pflaumenbaum-rote Tasse',
        price: 36.00,
    },
    {
        id: '7',
        referenceNumber: 'AS-7',
        title: 'Pflaumenbaum-weiße Handyhülle',
        price: 130.00,
    },
    {
        id: '8',
        referenceNumber: 'AS-8',
        title: 'Pflaumenbaum-schwarze Handyhülle',
        price: 63.00,
    },
    {
        id: '9',
        referenceNumber: 'AS-9',
        title: 'Pflaumenbaum-rote Handyhülle',
        price: 76.00,
    },
    {
        id: '10',
        referenceNumber: 'AS-10',
        title: 'Pflaumenbaum-T-Shirt',
        price: 6.95,
    },
    {
        id: '11',
        referenceNumber: 'AS-11',
        title: 'Pflaumenbaum-Aufkleber-Set',
        price: 44.00,
    },
];

const product: (id: string)
 => ResolverProductType = (id) => products.find((item) => item.id === id)!;

export { products, product };
