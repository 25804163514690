import { LeaderLegsType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetLeaderLegsByIdQueryQuery extends
    AbstractQueryResource<GetLeaderLegsByIdQueryResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($id: String!, $limit: Int!, $offset: Int!) {
            enrollee(id: $id) {
                leaderLegs(limit: $limit, offset: $offset) {
                    activeGroupEnrolleesCount,
                    id,
                    totals {
                        pv,
                    },
                    firstName,
                    lastName,
                    rank {
                        id,
                        label,
                        power,
                    },
                    payRank {
                        id,
                        label,
                        power,
                    },
                },
                leaderLegsCount,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getLeaderLegsListById';
    }
}

export type GetLeaderLegsByIdQueryResultType = {
    enrollee: {
        leaderLegs: LeaderLegsType[];
        leaderLegsCount: number;
    }
}

export type GetLeaderLegsByIdQueryParamsType = {
    id: string;
    limit: number;
    offset: number;
}
