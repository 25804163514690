import { EVENT_DESCRIPTION_MAX_LENGTH } from '../constants';

const descriptionLengthValidation: (val: string, t) => string|null = (val, t) => {
    if (JSON.stringify(val).length > EVENT_DESCRIPTION_MAX_LENGTH) {
        return t('validation.eventDescriptionMaxLength');
    }

    return null;
};

export { descriptionLengthValidation };
