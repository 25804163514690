// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { DashboardVolumesType } from './types';

export default class GetDashboardTotalsQuery extends
    AbstractQueryResource<GetDashboardTotalsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            profile {
                totals {
                    pv,
                    gv,
                    spa_gv,
                    ov,
                }
            },
        }`;
    }

    protected getCacheCondition() {
        return 0.05;
    }

    protected getName(): string {
        return 'getDashboardTotals';
    }
}

export type GetDashboardTotalsResultType = {
    profile: {
        totals: DashboardVolumesType;
    };
}

export type GetDashboardTotalsParamsType = {}
