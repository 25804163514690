
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { EVENT_ATTENDANCE_STATUS } from '@/modules/Event/constants';
import eventView from '@/modules/Event/EventView';
import SocialButtons from '@/projectComponents/socialButtons/index.vue';
import { EventViewType, InvitationToEventType } from '@/api/graphQL/graphNodes/types';
import { ErrorType } from '@plumtreesystems/utils';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import EventGuests from './Info/guests.vue';

@Component({
    components: {
        SocialButtons,
        PropertyInfoDisplay,
        EventGuests,
    },
})
export default class EventInviteView extends Vue {
    @Prop({ required: true }) private event!: EventViewType;

    @Prop({ default: [] }) private invitations!: InvitationToEventType[];

    @Get(eventView) private showQrCode!: boolean;

    @Get(eventView) private showEmailShare!: boolean;

    @Sync(eventView, 'emailInviteForm.firstName') private inviteFirstName!: string;

    @Sync(eventView, 'emailInviteForm.lastName') private inviteLastName!: string;

    @Sync(eventView, 'emailInviteForm.email') private inviteEmail!: string;

    @Get(eventView) private eventInvitationLink!: string;

    @Get(eventView) private formErrors!: ErrorType;

    @Get(eventView) private sendingInvite!: boolean;

    get displayInvite() {
        return this.event.confirmed && eventView.shareAvailable && !this.eventCampaign;
    }

    get eventCampaign() {
        return eventView.eventCampaignEnded;
    }

    get eventGuests() {
        return this.$t('view.event.view.invitesSent', { count: this.invitations.length });
    }

    get eventGuestsStatus() {
        const { invitations } = this;

        const goingGuests: number = invitations
            .filter((invitation) => invitation.attending === EVENT_ATTENDANCE_STATUS.yes)
            .length;

        const tentativeGuests: number = invitations
            .filter((invitation) => invitation.attending === EVENT_ATTENDANCE_STATUS.tentative)
            .length;

        const awaitingGuests: number = invitations
            .filter((invitation) => !invitation.attending)
            .length;

        const declinedGuests: number = invitations
            .filter((invitation) => invitation.attending === EVENT_ATTENDANCE_STATUS.declined)
            .length;

        return this.$t('view.event.calendar.eventGuestsStatus', {
            goingGuests, tentativeGuests, awaitingGuests, declinedGuests,
        });
    }

    handleInvite(val: string) {
        switch (val) {
        case 'email': {
            eventView.toggleEmailShare();
            break;
        }
        case 'qr': {
            eventView.toggleQrCode();
            break;
        }
        default:
            break;
        }
    }

    closeDialog(val: string) {
        switch (val) {
        case 'email': {
            eventView.toggleEmailShare();
            break;
        }
        case 'qr': {
            eventView.toggleQrCode();
            break;
        }
        default:
            break;
        }
    }

    async handleInviteSubmit() {
        eventView.sendInviteEmail();
    }
}
