import {
    DATE_PICKER_CONFIG_DAY, DATE_PICKER_CONFIG_MONTH,
    DATE_PICKER_CONFIG_SHORT_DAY, DATE_PICKER_CONFIG_SHORT_MONTH,
} from '../constants';

export default (t, key): string[]|null => {
    switch (key) {
    case DATE_PICKER_CONFIG_DAY:
        return [
            t('ui.time.long.day.mon'),
            t('ui.time.long.day.tue'),
            t('ui.time.long.day.wed'),
            t('ui.time.long.day.thu'),
            t('ui.time.long.day.fri'),
            t('ui.time.long.day.sat'),
            t('ui.time.long.day.sun'),
        ];
    case DATE_PICKER_CONFIG_SHORT_DAY:
        return [
            t('ui.time.short.day.mon'),
            t('ui.time.short.day.tue'),
            t('ui.time.short.day.wed'),
            t('ui.time.short.day.thu'),
            t('ui.time.short.day.fri'),
            t('ui.time.short.day.sat'),
            t('ui.time.short.day.sun'),
        ];
    case DATE_PICKER_CONFIG_MONTH:
        return [
            t('ui.time.long.month.jan'),
            t('ui.time.long.month.feb'),
            t('ui.time.long.month.mar'),
            t('ui.time.long.month.apr'),
            t('ui.time.long.month.may'),
            t('ui.time.long.month.jun'),
            t('ui.time.long.month.jul'),
            t('ui.time.long.month.aug'),
            t('ui.time.long.month.sep'),
            t('ui.time.long.month.oct'),
            t('ui.time.long.month.nov'),
            t('ui.time.long.month.dec'),
        ];
    case DATE_PICKER_CONFIG_SHORT_MONTH:
        return [
            t('ui.time.short.month.jan'),
            t('ui.time.short.month.feb'),
            t('ui.time.short.month.mar'),
            t('ui.time.short.month.apr'),
            t('ui.time.short.month.may'),
            t('ui.time.short.month.jun'),
            t('ui.time.short.month.jul'),
            t('ui.time.short.month.aug'),
            t('ui.time.short.month.sep'),
            t('ui.time.short.month.oct'),
            t('ui.time.short.month.nov'),
            t('ui.time.short.month.dec'),
        ];
    default:
        return null;
    }
};
