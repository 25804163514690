
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import { RankType } from '@/api/graphQL/graphNodes/types';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class QualifiedLeader extends Vue {
    @Get(dashboard, 'data.qualifiedOrgLeaderCount') private qualifiedOrgLeaderCount!: number;

    @Prop() private rank!: RankType;

    @Prop() private requirements!: RankRequirementType[];

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(
            RankRequirementManager.rankRequirementMetricConstants.qualifiedLeaders,
            this.$t,
        ).title;
    }

    get requirement(): string | null {
        const qualifiedOrgLeaderCount = this.requirements
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.qualifiedLeaders);

        return qualifiedOrgLeaderCount ? qualifiedOrgLeaderCount.value : null;
    }

    get displayData(): boolean {
        if (!this.requirement) {
            return false;
        }

        return true;
    }
}
