import { AssetType, GenealogyEnrolleeType } from '@/api/graphQL/graphNodes/types';
import { AddressSelectOptionsType } from './AddressSelect/types';

export const defaultMinimalParentLeg: () => GenealogyEnrolleeType = () => ({
    ambassadorLegsCount: 0,
    email: '',
    firstName: '',
    lastName: '',
    id: '',
    leaderLegsCount: 0,
    personalRecruitsCount: 0,
    qualifiedBranchesCount: 0,
    address: '',
    secondAddress: '',
    postCode: '',
    country: '',
    county: '',
    town: '',
    joinDate: '',
    secondPhoneNumber: '',
    phoneNumber: '',
    parent: {
        address: '',
        firstName: '',
        lastName: '',
        secondAddress: '',
        postCode: '',
        country: '',
        county: '',
        town: '',
        secondPhoneNumber: '',
        joinDate: '',
        email: '',
        id: '',
        phoneNumber: '',
    },
    stats: [],
    totals: {
        gv: 0,
        ov: 0,
        pv: 0,
    },
    rank: {
        id: '',
        label: '',
        power: 0,
    },
});

export const defaultLeg: () => GenealogyEnrolleeType = () => ({
    parent: {
        ...defaultMinimalParentLeg().parent!,
        totals: {
            gv: 0,
            ov: 0,
            pv: 0,
        },
    },
    ...defaultMinimalParentLeg(),
});

export const defaultAsset: () => AssetType = () => ({
    category: '',
    fileName: '',
    id: '',
    title: '',
    uploadDate: '',
    link: '',
    priority: -1,
    context: [],
    originalFileName: '',
    subCategory: null,
});

export const defaultAddressOption: () => AddressSelectOptionsType = () => ({
    description: '',
    highlight: '',
    id: '',
    text: '',
    type: '',
});
