import { HostessRewardsType } from '@/api/graphQL/graphNodes/types';

const hostessRewards: () => HostessRewardsType[] = () => [
    {
        label: '1',
        thresholdEUR: 150,
        thresholdGBP: 150,
        rewards: [
            '1 x Exklusives Produkt',
            '10 Bonus-Token',
        ],
    },
    {
        label: '2',
        thresholdEUR: 300,
        thresholdGBP: 300,
        rewards: [
            '1 x Exklusives Produkt',
            '30 Bonus-Token',
        ],
    },
    {
        label: '3',
        thresholdEUR: 450,
        thresholdGBP: 450,
        rewards: [
            '2 x Exklusives Produkt',
            '60 Bonus-Token',
        ],
    },
    {
        label: '4',
        thresholdEUR: 600,
        thresholdGBP: 600,
        rewards: [
            '2 x Exklusives Produkt',
            '100 Bonus-Token',
        ],
    },
    {
        label: '5',
        thresholdEUR: 750,
        thresholdGBP: 750,
        rewards: [
            '3 x Exklusives Produkt',
            '150 Bonus-Token',
        ],
    },
];

export { hostessRewards };
