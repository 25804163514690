import { PassportType } from '@/api/graphQL/graphNodes/types';
import { ValidationErrorType } from '../../types';

const registrationPassportFormValidation:
    (val: PassportType, t) =>
    ValidationErrorType[] = (val, t) => {
        const errors: ValidationErrorType[] = [];

        if (!val.passport) {
            errors.push({ key: 'passport', val: t('validation.requiredField') });
        }

        if (val.passport && val.passport.length !== 44) {
            errors.push({ key: 'passport', val: t('validation.passportMrz') });
        }

        return errors;
    };

export default registrationPassportFormValidation;
