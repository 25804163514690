import { Impersonator } from '@plumtreesystems/utils';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { ProfileType, TermsAndConditionsType } from './types';

export default class GetProfileQuery extends
    AbstractQueryResource<GetProfileResultType> {
    protected getQuery(): string {
        const bankDetails = `
            bankDetails {
                bankAccountName,
                bankAccountNumber,
                bankSortCode,
            },
        `;

        const businessDetails = `
            businessDetails {
                businessName,
                businessAddress
            }
        `;

        return `query ${this.getName()}($type: String!) {
            profile {
                id,
                firstName,
                lastName,
                phoneNumber,
                email,
                address,
                secondAddress,
                birthDate,
                postCode,
                town,
                county,
                country,
                region,
                parentId,
                joinDate,
                rank {
                    id,
                    label,
                },
                ${Impersonator.isImpersonating() ? '' : bankDetails}
                profilePicture {
                    link,
                    context {
                        metric,
                        value
                    }
                },
                ${Impersonator.isImpersonating() ? '' : businessDetails}
            },
            details: profile {
                vanityUrl,
            },
            termsAndConditions(type: $type) {
                id,
                createdAt,
                content {
                    id,
                    category,
                    link,
                    fileName,
                    context {
                        metric,
                        value,
                    },
                    title,
                    originalFileName,
                    uploadDate,
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getProfile';
    }
}

export type GetProfileResultType = {
    profile: ProfileType;
    details: {
        vanityUrl: string;
    }
    termsAndConditions: TermsAndConditionsType;
}

export type ProfileParamsType = {
    type: string;
}
