import { BusinessDetailsType } from '@/api/graphQL/graphNodes/types';
import { ResolverBusinessDetailsType } from '../types';

const details: ResolverBusinessDetailsType[] = [
    {
        businessName: 'Sarah-Geschäft',
        businessAddress: 'Albrechtstrasse 62, Friedberg 86305, Deutschland',
        id: '0',
    },
];

const businessDetails: (id: string) => null|BusinessDetailsType = (id) => {
    const res = details.find((item) => item.id === id);
    return res || null;
};

export {
    businessDetails,
};
