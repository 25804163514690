// import { BALANCE_CURRENCY } from './constants';

// export const currencySign: (currency: string) => string = (currency) => {
//     if (currency === BALANCE_CURRENCY.gbp) {
//         return '£';
//     }
//     return '€';
// };

export const currencySign: (currency: string) => string = () => '€';
