
import { Vue, Component } from 'vue-property-decorator';
import profile from '@/modules/Profile';
import auth from '@/modules/Auth';
import impersonate from '@/modules/Impersonation';
import { Get } from '@/utils/vuex-module-mutators';
import env from '@/environment';
import system from '@/modules/System';
import NavItem from './Item/index.vue';
import Section from './Section/index.vue';

@Component({
    components: {
        NavItem,
        Section,
    },
})
export default class Navigation extends Vue {
    @Get(profile, 'originalData.firstName') private firstName!: string;

    @Get(profile, 'originalData.lastName') private lastName!: string;

    @Get(profile, 'originalData.id') private id!: string;

    @Get(impersonate) private token!: string|null;

    @Get(system) private screenType!: string;

    @Get(profile) private shopLoginUrl!: string;

    get isDevelopmentEnvironment(): boolean {
        return env.VUE_APP_DEV === 'true';
    }

    get impersonating(): boolean {
        return impersonate.impersonating;
    }

    get isAmbassador(): boolean {
        return auth.isAmbassador;
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    get baseUrl() {
        return env.BASE_URL;
    }

    unlockedAfterStage(stage: number): boolean {
        return Number(env.VUE_APP_STAGE) > stage;
    }
}
