var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"LeaderLegsStatusTooltipContent__Content",domProps:{"innerHTML":_vm._s(_vm.$t(
        'projectComponents.leaderLegsStatusTooltip.content',
        {
            ps: `${_vm.currencyPrefix} ${_vm.ps}`,
            personalRecruits: _vm.personalRecruits,
            gv: `${_vm.currencyPrefix} ${_vm.gv}`
        }
    ))}})
}
var staticRenderFns = []

export { render, staticRenderFns }