
import { Vue, Component, Prop } from 'vue-property-decorator';
import RankRequirementManager from '@/utils/rankRequirementManager';
import requirementLeaderLegs from '@/modules/LeaderLegs/requirementLeaderLegs';
import requirementRisingStars from '@/modules/RisingStars/requirementRisingStars';
import system from '@/modules/System';
import { Get } from '@/utils/vuex-module-mutators';
import CurrencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class RankRequirementsItem extends Vue {
    @Prop() private metric!: string;

    @Prop() private required!: number;

    @Prop() private current!: number;

    @Prop({ default: '' }) private id!: string;

    @Prop({ default: false }) private isMobile!: boolean;

    @Prop({ default: true }) private withRankRequirementsDetails!: boolean;

    @Prop() private progressBarAriaLabel!: string;

    @Prop({ default: '' }) customTitle!: string;

    @Prop({ default: true }) withFulfilledIcon!: boolean;

    @Prop({ default: false }) withCustomCircularProgressBar!: boolean;

    @Prop({ default: false }) customCircularProgressBarLabel!: string;

    @Get(system) screenType!: string;

    get currentVal(): string {
        if (this.displayPrefix) {
            return `${this.currencyPrefix} ${Number(this.current).toFixed(2)}`;
        }

        return Number(this.current).toFixed(0);
    }

    get requiredVal(): string {
        if (this.displayPrefix) {
            return `${this.currencyPrefix} ${Number(this.required).toFixed(2)}`;
        }

        return Number(this.required).toFixed(0);
    }

    get displayPrefix(): boolean {
        return ['pv', 'spa_gv', 'ov', 'uv', 'gv'].includes(this.metric);
    }

    get labelClasses() {
        return [
            'DownlineRankRequirementsItem__ProgressBarLabel',
            {
                RankRequirementsItem__LinkLabel: this.linkLabel && this.withRankRequirementsDetails,
            },
        ];
    }

    get linkLabel(): boolean {
        return this.isLeaderLegs || this.isAmbassadorLegs;
    }

    get isLeaderLegs() {
        const { leaderLegs } = RankRequirementManager
            .rankRequirementMetricConstants;

        return this.metric === leaderLegs;
    }

    get isAmbassadorLegs() {
        const { ambassadorLegs } = RankRequirementManager
            .rankRequirementMetricConstants;

        return this.metric === ambassadorLegs;
    }

    get routeName() {
        return this.$route.name;
    }

    get label() {
        return RankRequirementManager.getRankRequirementLabel(this.metric, this.$t, this.current);
    }

    get percentageLabel() {
        if (this.percentage < 100) {
            return `${Math.round(this.percentage)}%`;
        }

        return '100%';
    }

    get percentage(): number {
        if (this.required < 1) {
            return 100;
        }

        const percent = 100 / this.required;
        return percent * this.current;
    }

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    openDetails() {
        if (this.isLeaderLegs && this.withRankRequirementsDetails) {
            requirementLeaderLegs.setActiveLegDetailsDialog(this.id);
        }

        if (this.isAmbassadorLegs && this.withRankRequirementsDetails) {
            requirementRisingStars.setActiveLegDetailsDialog(this.id);
        }
    }
}
