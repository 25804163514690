
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SelectOptionsType } from './types';

@Component
export default class Select extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop({ default: '' }) private label!: string;

    @Prop({ required: true }) private value!: string | number;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: false }) private dense!: boolean;

    @Prop({ default: true }) private required!: boolean;

    @Prop({ required: true }) private options!: SelectOptionsType[];

    @Prop({ default: '' }) private helperText!: string;

    @Prop({ default: '' }) private error!: string;

    @Prop({ default: '' }) private icon!: string;

    private containerClass: string[] = [];

    private open: boolean = false;

    private clickOutsideEvent: Function = () => {};

    private openingElement: any = null;

    private matchValueError: boolean = false;

    get elementClasses() {
        return [
            'SelectField',
            ...this.containerClass,
            { 'SelectField--error': this.error !== '' || this.matchValueError },
            { 'SelectField--disabled': this.disabled },
            { 'SelectField--dense': this.dense },
        ];
    }

    get labelClasses() {
        return [
            'SelectField__Label',
            { 'SelectField__Label--empty': this.label === '' },
        ];
    }

    optionalLabel(val: string) {
        return `${val} (${this.$t('ui.optional')})`;
    }

    addToContainerClass(items: string[]) {
        const combinedObject = new Set([...this.containerClass, ...items]);
        this.containerClass = [...combinedObject];
    }

    removeFromContainerClass(items: string[]) {
        this.containerClass = this.containerClass.filter((classItem) => !items.includes(classItem));
    }

    elementOnBlur(selected: boolean) {
        this.setActive(false, selected);
        this.removeFromContainerClass(['SelectField--focused']);
    }

    elementOnFocus() {
        this.setActive(true);
        this.addToContainerClass(['SelectField--focused']);
    }

    setActive(active: boolean, pulled: boolean = false) {
        const el: HTMLInputElement = this.$refs.selectInput as HTMLInputElement;

        if (active) {
            this.addToContainerClass(['SelectField--isPulled']);
        } else if (pulled) {
            this.addToContainerClass(['SelectField--isFilled']);
        } else if (el.value === '' || !this.findSelectedOption()) {
            this.removeFromContainerClass(['SelectField--isPulled', 'SelectField--isFilled']);
        } else {
            this.addToContainerClass(['SelectField--isFilled']);
        }
    }

    openSelect(): void {
        if (!this.disabled) {
            if (this.open) {
                this.closeSelect();
                return;
            }

            this.setOutsideClickListener();
            this.elementOnFocus();
            this.open = true;
        }
    }

    closeSelect(selected: boolean = false) {
        this.elementOnBlur(selected);
        this.open = false;
    }

    setOutsideClickListener(): void {
        const element = this.$refs.selectInputContainer;
        const elementIcon = this.$refs.selectInputIcon;
        if (this.openingElement === element || this.openingElement === elementIcon) {
            return;
        }

        this.openingElement = element;

        this.clickOutsideEvent = (event: Event) => {
            const selectInputEl: HTMLElement = this.$refs.selectInputContainer as HTMLElement;
            const selectInputElIcon: HTMLElement = elementIcon as HTMLElement;
            const selectedEventNode: Node = event.target as Node;
            if (!(
                selectInputEl === event.target
                || selectInputEl.contains(selectedEventNode)
            ) && (
                !selectInputElIcon.contains(selectedEventNode)
            )) {
                this.closeFormDataSelectContainer();
            }
        };

        document.addEventListener('click', this.clickOutsideEvent as EventListener);
    }

    closeFormDataSelectContainer(selected: boolean = false): void {
        this.closeSelect(selected);
        this.openingElement = null;
        document.removeEventListener('click', this.clickOutsideEvent as EventListener);
    }

    handleUpdate(val: string | number) {
        this.$emit('input', val);
        this.closeFormDataSelectContainer(true);
    }

    findSelectedOption() {
        const result = this.options.find((item) => item.value === this.value);

        return result || null;
    }

    getDisplayValue() {
        if (this.value !== '') {
            const selectedOption = this.findSelectedOption();

            if (selectedOption) {
                this.matchValueError = false;

                return selectedOption.name;
            }

            this.matchValueError = true;
        }

        return '';
    }

    created() {
        if (this.value !== '' && this.findSelectedOption()) {
            this.addToContainerClass(['SelectField--isFilled', 'SelectField--isPulled']);
        }
    }

    beforeUpdate() {
        if (this.value === '' || !this.findSelectedOption()) {
            this.removeFromContainerClass(['SelectField--isPulled', 'SelectField--isFilled']);
        } else {
            const selectedOption = this.findSelectedOption();

            if (selectedOption) {
                this.addToContainerClass(['SelectField--isPulled', 'SelectField--isFilled']);
            } else {
                this.removeFromContainerClass(['SelectField--isPulled', 'SelectField--isFilled']);
            }
        }
    }
}
