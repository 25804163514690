import { defaultData } from '@/modules/IncomeSummary/defaults';
import { rank as defaultRank } from '@/modules/Rank/defaults';
import { CommissionBonusType } from '@/api/graphQL/graphNodes/types';
import { enrollee as findEnrollee } from './enrollee';
import { rank } from './ranks';
import { ResolverIncomeBonusesType, ResolverIncomeSummaryType } from '../types';

const defaultBonus: () => ResolverIncomeBonusesType = () => ({
    enrollee: '',
    bonusTotals: [
        {
            key: 'centralTeam',
            label: 'Zentrale Team Bonus',
            amount: '0',
        },
        {
            key: 'leadership1',
            label: 'Führung 1',
            amount: '0',
        },
        {
            key: 'leadership2',
            label: 'Führung 2',
            amount: '0',
        },
        {
            key: 'leadership3',
            label: 'Führung 3',
            amount: '0',
        },
        {
            key: 'leadership4',
            label: 'Führung 4',
            amount: '0',
        },
        {
            key: 'additional',
            label: 'Zusätzlicher Verkaufsbonus',
            amount: '0',
        },
        {
            key: 'productivity',
            label: 'Produktivitätsbonus',
            amount: '0',
        },
        {
            key: 'adjustments',
            label: 'Anpassungen',
            amount: '0',
        },
    ],
});

const bonuses: ResolverIncomeBonusesType[] = [
    {
        enrollee: '0',
        bonusTotals: [
            {
                key: 'centralTeam',
                label: 'Zentrale Team Bonus',
                amount: '443.72',
            },
            {
                key: 'leadership1',
                label: 'Führung 1',
                amount: '414.25',
            },
            {
                key: 'leadership2',
                label: 'Führung 2',
                amount: '0',
            },
            {
                key: 'leadership3',
                label: 'Führung 3',
                amount: '0',
            },
            {
                key: 'leadership4',
                label: 'Führung 4',
                amount: '0',
            },
            {
                key: 'additional',
                label: 'Zusätzlicher Verkaufsbonus',
                amount: '0',
            },
            {
                key: 'productivity',
                label: 'Produktivitätsbonus',
                amount: '100',
            },
            {
                key: 'adjustments',
                label: 'Anpassungen',
                amount: '213',
            },
        ],
    },
    {
        enrollee: '5',
        bonusTotals: [
            {
                key: 'centralTeam',
                label: 'Zentrale Team Bonus',
                amount: '202.38',
            },
            {
                key: 'leadership1',
                label: 'Führung 1',
                amount: '0',
            },
            {
                key: 'leadership2',
                label: 'Führung 2',
                amount: '0',
            },
            {
                key: 'leadership3',
                label: 'Führung 3',
                amount: '0',
            },
            {
                key: 'leadership4',
                label: 'Führung 4',
                amount: '0',
            },
            {
                key: 'additional',
                label: 'Zusätzlicher Verkaufsbonus',
                amount: '0',
            },
            {
                key: 'productivity',
                label: 'Produktivitätsbonus',
                amount: '0',
            },
            {
                key: 'adjustments',
                label: 'Anpassungen',
                amount: '112',
            },
        ],
    },
    {
        enrollee: '12',
        bonusTotals: [
            {
                key: 'centralTeam',
                label: 'Zentrale Team Bonus',
                amount: '114.00',
            },
            {
                key: 'leadership1',
                label: 'Führung 1',
                amount: '0',
            },
            {
                key: 'leadership2',
                label: 'Führung 2',
                amount: '0',
            },
            {
                key: 'leadership3',
                label: 'Führung 3',
                amount: '0',
            },
            {
                key: 'leadership4',
                label: 'Führung 4',
                amount: '0',
            },
            {
                key: 'additional',
                label: 'Zusätzlicher Verkaufsbonus',
                amount: '0',
            },
            {
                key: 'productivity',
                label: 'Produktivitätsbonus',
                amount: '0',
            },
            {
                key: 'adjustments',
                label: 'Anpassungen',
                amount: '93',
            },
        ],
    },
    {
        enrollee: '18',
        bonusTotals: [
            {
                key: 'centralTeam',
                label: 'Zentrale Team Bonus',
                amount: '196.98',
            },
            {
                key: 'leadership1',
                label: 'Führung 1',
                amount: '0',
            },
            {
                key: 'leadership2',
                label: 'Führung 2',
                amount: '0',
            },
            {
                key: 'leadership3',
                label: 'Führung 3',
                amount: '0',
            },
            {
                key: 'leadership4',
                label: 'Führung 4',
                amount: '0',
            },
            {
                key: 'additional',
                label: 'Zusätzlicher Verkaufsbonus',
                amount: '0',
            },
            {
                key: 'productivity',
                label: 'Produktivitätsbonus',
                amount: '0',
            },
            {
                key: 'adjustments',
                label: 'Anpassungen',
                amount: '150',
            },
        ],
    },
    {
        enrollee: '25',
        bonusTotals: [
            {
                key: 'centralTeam',
                label: 'Zentrale Team Bonus',
                amount: '185.40',
            },
            {
                key: 'leadership1',
                label: 'Führung 1',
                amount: '219.44',
            },
            {
                key: 'leadership2',
                label: 'Führung 2',
                amount: '0',
            },
            {
                key: 'leadership3',
                label: 'Führung 3',
                amount: '0',
            },
            {
                key: 'leadership4',
                label: 'Führung 4',
                amount: '0',
            },
            {
                key: 'additional',
                label: 'Zusätzlicher Verkaufsbonus',
                amount: '0',
            },
            {
                key: 'productivity',
                label: 'Produktivitätsbonus',
                amount: '0',
            },
            {
                key: 'adjustments',
                label: 'Anpassungen',
                amount: '0',
            },
        ],
    },
    {
        enrollee: '29',
        bonusTotals: [
            {
                key: 'centralTeam',
                label: 'Zentrale Team Bonus',
                amount: '0',
            },
            {
                key: 'leadership1',
                label: 'Führung 1',
                amount: '0',
            },
            {
                key: 'leadership2',
                label: 'Führung 2',
                amount: '0',
            },
            {
                key: 'leadership3',
                label: 'Führung 3',
                amount: '0',
            },
            {
                key: 'leadership4',
                label: 'Führung 4',
                amount: '0',
            },
            {
                key: 'additional',
                label: 'Zusätzlicher Verkaufsbonus',
                amount: '67.25',
            },
            {
                key: 'productivity',
                label: 'Produktivitätsbonus',
                amount: '0',
            },
            {
                key: 'adjustments',
                label: 'Anpassungen',
                amount: '0',
            },
        ],
    },
    {
        enrollee: '31',
        bonusTotals: [
            {
                key: 'centralTeam',
                label: 'Zentrale Team Bonus',
                amount: '202.56',
            },
            {
                key: 'leadership1',
                label: 'Führung 1',
                amount: '0',
            },
            {
                key: 'leadership2',
                label: 'Führung 2',
                amount: '0',
            },
            {
                key: 'leadership3',
                label: 'Führung 3',
                amount: '0',
            },
            {
                key: 'leadership4',
                label: 'Führung 4',
                amount: '0',
            },
            {
                key: 'additional',
                label: 'Zusätzlicher Verkaufsbonus',
                amount: '66.15',
            },
            {
                key: 'productivity',
                label: 'Produktivitätsbonus',
                amount: '0',
            },
            {
                key: 'adjustments',
                label: 'Anpassungen',
                amount: '100',
            },
        ],
    },
    {
        enrollee: '37',
        bonusTotals: [
            {
                key: 'centralTeam',
                label: 'Zentrale Team Bonus',
                amount: '0',
            },
            {
                key: 'leadership1',
                label: 'Führung 1',
                amount: '0',
            },
            {
                key: 'leadership2',
                label: 'Führung 2',
                amount: '0',
            },
            {
                key: 'leadership3',
                label: 'Führung 3',
                amount: '0',
            },
            {
                key: 'leadership4',
                label: 'Führung 4',
                amount: '0',
            },
            {
                key: 'additional',
                label: 'Zusätzlicher Verkaufsbonus',
                amount: '75.60',
            },
            {
                key: 'productivity',
                label: 'Produktivitätsbonus',
                amount: '0',
            },
            {
                key: 'adjustments',
                label: 'Anpassungen',
                amount: '0',
            },
        ],
    },
];

const calculateTotal: (data: CommissionBonusType[]) => number = (data) => {
    let res = 0;
    data.forEach((val) => {
        res += Number(val.amount);
    });

    return res;
};

const incomeSummary: (year: string, id: string) => ResolverIncomeSummaryType = (year, id) => {
    const calculations = bonuses.find((b) => b.enrollee === id);
    const calculatedBonuses = calculations || defaultBonus();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { enrollee, ...bonusData } = calculatedBonuses;
    const summaryEnrollee = findEnrollee(id);
    const rankEl = rank(summaryEnrollee.rankId) || defaultRank();

    const res: ResolverIncomeSummaryType = {
        ...defaultData(),
        gv: summaryEnrollee.totals.gv,
        pv: summaryEnrollee.totals.pv,
        ov: summaryEnrollee.totals.ov,
        title: rankEl,
        ...bonusData,
        total: calculateTotal(bonusData.bonusTotals),
    };

    return res;
};

export { incomeSummary };
