import { SelectOptionsType } from '@/components/select/types';
import { CALENDAR_EVENTS_FINISH_STATUS_OPTIONS, CALENDAR_TIME_FRAME_OPTIONS } from './constants';

const calendarTimeFrameOptions: (t) => SelectOptionsType[] = (t) => ([
    {
        name: t('common.month'),
        value: CALENDAR_TIME_FRAME_OPTIONS.month,
    },
    {
        name: t('common.week'),
        value: CALENDAR_TIME_FRAME_OPTIONS.week,
    },
    {
        name: t('common.day'),
        value: CALENDAR_TIME_FRAME_OPTIONS.day,
    },
]);

const calendarEventsStatusOptions: (t: any) => SelectOptionsType[] = (t) => ([
    {
        name: t('common.notDone'),
        value: CALENDAR_EVENTS_FINISH_STATUS_OPTIONS.notDone,
    },
    {
        name: t('common.done'),
        value: CALENDAR_EVENTS_FINISH_STATUS_OPTIONS.done,
    },
    {
        name: t('common.all'),
        value: CALENDAR_EVENTS_FINISH_STATUS_OPTIONS.all,
    },
]);

export {
    calendarTimeFrameOptions, calendarEventsStatusOptions,
};
