import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import { BUSINESS_DEVELOPMENT_TYPES, MILESTONES_TOOLTIP_TYPES, MILESTONE_KEY } from '../constants';

class WildcardManager {
    wildcardLabelResolver(type: string, t) {
        if (type === MILESTONE_KEY.consistentLeaderWildcard) {
            return t('view.milestone.consistent');
        }

        if (type === MILESTONE_KEY.newLeaderWildcard) {
            return t('view.milestone.newLeader');
        }

        if (type === MILESTONE_KEY.activityWildcard) {
            return t('view.milestone.activity');
        }

        if (type === MILESTONE_KEY.travelSelling) {
            return t('view.milestone.sales');
        }

        if (type === MILESTONE_KEY.travelRecruiting) {
            return t('view.milestone.recruiting');
        }

        if (type === MILESTONE_KEY.travelPromoting) {
            return t('view.milestone.promoting');
        }

        if (type === MILESTONE_KEY.travelLeadership) {
            return t('view.milestone.leadership');
        }

        if (type === MILESTONE_KEY.fakeTravel) {
            return t('view.milestone.overall');
        }

        return t('view.milestone.newLeader');
    }

    resolveLabelType(milestones: MilestoneType[], sortByAchievable: boolean = false) {
        const gold = milestones.find((item) => item.category === 'gold');
        const silver = milestones.find((item) => item.category === 'silver');
        const bronze = milestones.find((item) => item.category === 'bronze');
        let res = '';

        switch (true) {
        case !!gold && (sortByAchievable ? !gold.unachievable : gold.achieved):
            res = BUSINESS_DEVELOPMENT_TYPES.gold;
            break;
        case !!silver && (sortByAchievable ? !silver.unachievable : silver.achieved):
            res = BUSINESS_DEVELOPMENT_TYPES.silver;
            break;
        case !!bronze && (sortByAchievable ? !bronze.unachievable : bronze.achieved):
            res = BUSINESS_DEVELOPMENT_TYPES.bronze;
            break;
        default:
            res = 'unachievable';
            break;
        }

        return res;
    }

    businessDevelopmentLabelResolver(
        milestones: MilestoneType[],
        t,
        sortByAchievable: boolean = false,
    ) {
        const type = this.resolveLabelType(milestones, sortByAchievable);
        let label = '';

        switch (type) {
        case BUSINESS_DEVELOPMENT_TYPES.gold:
            label = t('view.milestone.businesDevelopment.gold');
            break;
        case BUSINESS_DEVELOPMENT_TYPES.silver:
            label = t('view.milestone.businesDevelopment.silver');

            break;
        case BUSINESS_DEVELOPMENT_TYPES.bronze:
            label = t('view.milestone.businesDevelopment.bronze');

            break;

        default:
            label = t('common.na');
            break;
        }
        return label;
    }

    quickStartLabelResolver(type: string, t) {
        let res = {
            short: t('view.milestone.quickStart.short.kitBoost'),
            full: t('view.milestone.quickStart.full.kitBoost'),
        };

        switch (type) {
        case 'kit_refund':
            res = {
                short: t('view.milestone.quickStart.short.kitRefund'),
                full: t('view.milestone.quickStart.full.kitRefund'),
            };
            break;

        case 'business_boost':
            res = {
                short: t('view.milestone.quickStart.short.businessBoost'),
                full: t('view.milestone.quickStart.full.businessBoost'),
            };
            break;

        case 'double_promotion':
            res = {
                short: t('view.milestone.quickStart.short.doublePromotion'),
                full: t('view.milestone.quickStart.full.doublePromotion'),
            };
            break;

        default:
            break;
        }

        return res;
    }

    pathwayTooltipResolver(key, t) {
        let res = '';
        switch (key) {
        case MILESTONES_TOOLTIP_TYPES.travel:
            res = t('view.milestone.tooltips.pathway.travel');
            break;
        case MILESTONES_TOOLTIP_TYPES.business:
            res = t('view.milestone.tooltips.pathway.business');
            break;
        case MILESTONES_TOOLTIP_TYPES.quick:
            res = t('view.milestone.tooltips.pathway.quick');
            break;
        case MILESTONES_TOOLTIP_TYPES.wildcard:
            res = t('view.milestone.tooltips.pathway.wildcard');
            break;

        default:
            break;
        }

        return res;
    }

    directionResolver(position: number, previousPosition: number|null) {
        let direction = 'stay';

        if (previousPosition) {
            if (Number(previousPosition) > Number(position)) {
                direction = 'up';
            } else if (Number(previousPosition) < Number(position)) {
                direction = 'down';
            }
        }

        return direction;
    }

    resolveDirectionIcon(position: number, previousPosition: number|null, baseUrl: string) {
        const direction = this.directionResolver(position, previousPosition);

        let icon = 'remove.svg';

        switch (direction) {
        case 'down':
            icon = 'moving_down.svg';
            break;
        case 'up':
            icon = 'moving_up.svg';
            break;
        default:
            break;
        }

        return `${baseUrl}img/icons/awards/${icon}`;
    }

    resolveWildcardRedirect(type: string): boolean {
        return [MILESTONE_KEY.travelSelling, MILESTONE_KEY.travelRecruiting,
            MILESTONE_KEY.travelLeadership, MILESTONE_KEY.fakeTravel].includes(type);
    }

    resolveAwardType(type: string) {
        let res: string = '';

        switch (type) {
        case MILESTONE_KEY.travelSelling:
            res = 'top_travel_seller';
            break;
        case MILESTONE_KEY.travelRecruiting:
            res = 'top_travel_recruiter';
            break;
        case MILESTONE_KEY.travelLeadership:
            res = 'top_travel_leader';
            break;
        case MILESTONE_KEY.fakeTravel:
            res = 'top_travel_overall';
            break;
        default:
            break;
        }
        return res;
    }

    resolveWildcardSize(type: string): number {
        let res: number = 0;
        switch (type) {
        case MILESTONE_KEY.travelLeadership:
            res = 5;
            break;
        case MILESTONE_KEY.travelRecruiting:
            res = 25;
            break;
        case MILESTONE_KEY.travelSelling:
            res = 50;
            break;

        default:
            break;
        }

        return res;
    }
}

export default new WildcardManager();
