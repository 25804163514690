import { DriverLicenseType } from '@/api/graphQL/graphNodes/types';
import { ValidationErrorType } from '../../types';

const registrationDriverLicenseFormValidation:
    (val: DriverLicenseType, t) =>
    ValidationErrorType[] = (val, t) => {
        const errors: ValidationErrorType[] = [];

        if (!val.drivingLicense) {
            errors.push({ key: 'drivingLicense', val: t('validation.requiredField') });
        }

        if (val.drivingLicense && val.drivingLicense.length !== 16) {
            errors.push({ key: 'drivingLicense', val: t('validation.driverLicenseFormat') });
        }

        return errors;
    };

export default registrationDriverLicenseFormValidation;
