import { EVENT_TYPE } from '@/modules/Event/constants';

export default (type: string, t): string => {
    switch (type) {
    case EVENT_TYPE.showcase: {
        return t('view.event.typeLabel.productShowcase');
    }
    case EVENT_TYPE.getTogether: {
        return t('view.event.typeLabel.getTogether');
    }
    case EVENT_TYPE.campaignLaunch: {
        return t('view.event.typeLabel.campaignLaunch');
    }
    case EVENT_TYPE.generalMeeting: {
        return t('view.event.typeLabel.generalMarketing');
    }
    case EVENT_TYPE.school: {
        return t('view.event.typeLabel.recruiting');
    }
    default: {
        return '';
    }
    }
};
