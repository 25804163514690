const descriptionResolver: (type: string, t) => string = (type, t) => {
    let res = '';

    switch (type) {
    case 'kit_boost_uk':
        res = t('view.newStarters.description.personalSales', { amount: '£360', days: 21 });
        break;
    case 'kit_boost_non_uk':
        res = t('view.newStarters.description.personalSales', { amount: '£360', days: 28 });
        break;
    case 'kit_refund_uk':
        res = t('view.newStarters.description.personalSales', { amount: '£720', days: 42 });
        break;
    case 'kit_refund_non_uk':
        res = t('view.newStarters.description.personalSales', { amount: '720', days: 49 });
        break;
    case 'business_boost_uk':
        res = t('view.newStarters.description.newRecruits', { days: 63 });
        break;
    case 'business_boost_non_uk':
        res = t('view.newStarters.description.newRecruits', { days: 70 });
        break;
    case 'double_promotion':
        res = t('view.newStarters.description.doublePromotion');
        break;
    default:
        break;
    }

    return res;
};

export default descriptionResolver;
