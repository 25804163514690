
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import rank from '@/modules/Rank';
import risingStars from '@/modules/RisingStars';
import Skeleton from '@/projectComponents/skeleton/List/index.vue';
import impersonation from '@/modules/Impersonation';
import system from '@/modules/System';
import LegsList from './LegsList/index.vue';
import Header from './Header/index.vue';

@Component({
    components: {
        LegsList,
        Header,
        Skeleton,
    },
})
export default class RisingStars extends Vue {
    @Get(rank, 'loading') private ranksLoading!: boolean;

    @Get(risingStars, 'loaded') private risingStarsLoaded!: boolean;

    @Get(risingStars, 'loading') private risingStarsLoading!: boolean;

    @Get(risingStars) private searchLoading!: boolean;

    @Get(risingStars) private displaySearchResults!: boolean;

    @Get(impersonation) private token!: string|null;

    @Get(system) private screenType!: string;

    get loading(): boolean {
        return !this.ranksLoading && !this.risingStarsLoading && !this.searchLoading;
    }

    get isMobile() {
        return this.screenType === 'mobile';
    }

    @Watch('token')
    loadLeaderLegs() {
        risingStars.getRisingStars({});
    }

    @Watch('displaySearchResults')
    loadData() {
        if (!this.risingStarsLoaded && !this.displaySearchResults) {
            this.loadLeaderLegs();
        }
    }

    beforeMount() {
        rank.getRanks();
        if (!this.displaySearchResults) {
            this.loadLeaderLegs();
        }
    }

    beforeDestroy() {
        risingStars.setSearchedLegs([]);
        risingStars.setSearchedOptionsLegs([]);
        risingStars.setData([]);
        risingStars.clearExpandedList();
        risingStars.setLoaded(false);
        risingStars.setDisplaySearchResults(false);
        risingStars.setSearchQuery('');
    }
}
