import { DownlineExplorerType } from '@/api/graphQL/graphNodes/types';

const defaultDownlineExplorerData: () => DownlineExplorerType = () => ({
    id: '',
    totals: {
        gv: 0,
        ov: 0,
        pv: 0,
    },
    directDownline: [],
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    secondAddress: null,
    postCode: null,
    country: null,
    county: null,
    town: null,
    secondPhoneNumber: null,
    parentId: null,
    phoneNumber: '',
    joinDate: '',
    parent: {
        firstName: '',
        lastName: '',
        address: '',
        secondAddress: null,
        postCode: null,
        country: null,
        county: null,
        town: null,
        secondPhoneNumber: null,
        email: '',
        joinDate: '',
        id: '',
        payRank: {
            id: '',
            label: '',
            power: 0,
        },
        rank: {
            id: '',
            label: '',
            power: 0,
        },
        phoneNumber: '',
        totals: {
            gv: 0,
            ov: 0,
            pv: 0,
        },
    },
    rankId: '',
    rank: {
        id: '',
        label: '',
        power: 0,
    },
    payRank: {
        id: '',
        label: '',
        power: 0,
    },
    directDownlineCount: 0,
});

export { defaultDownlineExplorerData };
