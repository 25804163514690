import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import componentsControl from '@/modules/ComponentsControls';
import {
    AssetType,
    BankDetailsType, BusinessDetailsType, BusinessDetailsUpdateType, ProfileType, ProfileUpdateType,
} from '@/api/graphQL/graphNodes/types';
import { UpdateProfileResultType } from '@/api/graphQL/graphNodes/UpdateProfileQuery';
import { GetProfileResultType, ProfileParamsType } from '@/api/graphQL/graphNodes/GetProfileQuery';
import Vue from 'vue';
import { UploadProfilePictureType } from '@/api/graphQL/graphNodes/UploadProfilePictureQuery';
import { FileType } from '@/components/fileArea/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import profileBusinessAddressSelect from '@/modules/ProfileBusinessAddressSelect';
import addressSelect from '@/modules/AddressSelect';
import profileFormValidation from './services/profileFormValidation';
import { ObjectPropertyType } from '../types';
import {
    profile as defaultProfile, defaultBankDetails, defaultProfilePictureData,
    defaultBusinessDetails,
} from './defaults';
import { FORM_ERROR_TOOLTIP_TIME_INTERVAL } from '../constants';
import ProfileRepository from './services/profileRepository';
import { TERMS_AND_CONDITIONS_TYPE } from './constants';

@Module({
    namespaced: true, dynamic: true, store, name: 'profile',
})
@AutoMutations
export class Profile extends VuexModule {
    private originalData: ProfileType = defaultProfile();

    private data: ProfileType = defaultProfile();

    private bankDetails: BankDetailsType = defaultBankDetails();

    private businessDetails: BusinessDetailsType = defaultBusinessDetails();

    private disableBusinessDetails: boolean = false;

    private profilePicture: UploadProfilePictureType = defaultProfilePictureData();

    private shopUrl: string = '';

    private shopLoginUrl: string = '';

    private loading: boolean = false;

    private imageDialogOpen: boolean = false;

    private profilePicUpload: FileType|null = null;

    private cameraDialogOpen: boolean = false;

    private formErrors: ErrorType = {};

    private displayTooltip: boolean = false;

    private vanityUrl: string = '';

    private termsDialogOpen: boolean = false;

    private termsAndConditionsFile: AssetType|null = null;

    get getProfileData(): ProfileType {
        return this.data;
    }

    @Mutation
    public setDisplayTooltip(val: boolean) {
        this.displayTooltip = val;
    }

    @Mutation
    public removeFormError(key) {
        const { formErrors } = this;
        delete formErrors[key];
        this.formErrors = { ...formErrors };
    }

    @Mutation
    public setProfileData(data: Partial<ProfileType>) {
        this.data = ObjectProcessor.objectMerge(defaultProfile(), data);
        this.originalData = ObjectProcessor.objectMerge(defaultProfile(), data);
    }

    @Mutation
    public setBankDetails(data: BankDetailsType) {
        this.bankDetails = { ...defaultBankDetails(), ...data };
    }

    @Mutation
    public setBusinessDetails(data: BusinessDetailsType) {
        this.businessDetails = { ...defaultBusinessDetails(), ...data };
    }

    @Mutation
    public setDisableBusinessDetails(val: boolean) {
        this.disableBusinessDetails = val;
    }

    @Mutation
    public setFormData(data: Partial<ProfileType>) {
        this.data = { ...this.data, ...data };
    }

    @Mutation
    public setProfilePicture(val: UploadProfilePictureType) {
        this.profilePicture = { ...val };
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public resetDataToOriginal() {
        this.data = { ...this.originalData };
        this.bankDetails = { ...defaultBankDetails() };
        this.businessDetails = { ...defaultBusinessDetails() };
    }

    @Mutation
    public setShopUrl(val: string) {
        this.shopUrl = val;
    }

    @Mutation
    public setShopLoginUrl(val: string) {
        this.shopLoginUrl = val;
    }

    @Mutation
    public setImageDialogOpen(val: boolean) {
        this.imageDialogOpen = val;
    }

    @Mutation
    public setCameraDialogOpen(val: boolean) {
        this.cameraDialogOpen = val;
    }

    @Mutation
    public clearFormErrors() {
        this.formErrors = {};
    }

    @Mutation
    public setFormError(payload: ObjectPropertyType) {
        this.formErrors[payload.key] = payload.val;
    }

    @Mutation
    public setFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Mutation
    public setVanityUrl(val: string) {
        this.vanityUrl = val;
    }

    @Mutation
    toggleTermsDialogOpen() {
        this.termsDialogOpen = !this.termsDialogOpen;
    }

    @Mutation
    public setTermsAndConditionsFile(val: AssetType|null) {
        this.termsAndConditionsFile = val;
    }

    @Action()
    public displayFormErrorsTooltip() {
        this.setDisplayTooltip(true);
        setTimeout(() => {
            this.setDisplayTooltip(false);
        }, FORM_ERROR_TOOLTIP_TIME_INTERVAL);
    }

    @Action()
    public validateInvitationForm(payload: {
        data: ProfileUpdateType;
        businessDetails: BusinessDetailsUpdateType;
    }) {
        this.clearFormErrors();
        const { data, businessDetails } = payload;
        // @ts-ignore
        const formErrors = profileFormValidation(data, businessDetails, this.store._vm.$t);
        formErrors.forEach((error) => this.setFormError(error));
    }

    @Action()
    public async getProfile(params: { isImpersonating: boolean, isAmbassador: boolean }) {
        try {
            this.setLoading(true);

            const { isImpersonating, isAmbassador } = params;
            const reqParams: ProfileParamsType = {
                type: isAmbassador
                    ? TERMS_AND_CONDITIONS_TYPE.ambassador
                    : TERMS_AND_CONDITIONS_TYPE.hostess,
            };
            const result: GetProfileResultType = await ProfileRepository
                .getProfile(reqParams) as GetProfileResultType;
            const { rank, businessDetails } = result.profile;

            // @ts-ignore
            Vue.$gtag.set({
                Rank: { label: rank.label, id: rank.id },
            });

            this.setVanityUrl(result.details.vanityUrl);

            if (result.profile.profilePicture !== null) {
                this.setProfilePicture(result.profile.profilePicture);
            } else {
                this.setProfilePicture(defaultProfilePictureData());
            }

            if (
                businessDetails
                && businessDetails.businessAddress
                && businessDetails.businessName
            ) {
                const formattedBusinessDetails = {
                    ...defaultBusinessDetails(),
                    ...ObjectProcessor.removeEmptyProperties(businessDetails),
                };

                this.setDisableBusinessDetails(true);
                this.setBusinessDetails(formattedBusinessDetails);
            } else {
                this.setDisableBusinessDetails(false);
                this.setBusinessDetails(defaultBusinessDetails());
            }

            if (result.termsAndConditions) {
                this.setTermsAndConditionsFile(result.termsAndConditions.content);
            }

            const formattedData = ObjectProcessor.removeEmptyProperties(result.profile);
            if (!isImpersonating) {
                const { bankSortCode } = result.profile.bankDetails;

                const formattedBankSortCode = bankSortCode === null ? '' : bankSortCode.replace(/-/g, '');
                this.setBankDetails(ObjectProcessor.removeEmptyProperties({
                    ...formattedData.bankDetails, bankSortCode: formattedBankSortCode,
                }));
            }

            this.setProfileData(formattedData);
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async updateProfile(isHostess: boolean = false) {
        try {
            const { addressId } = profileBusinessAddressSelect;
            const businessDetails: BusinessDetailsUpdateType = {
                ...this.businessDetails,
                businessAddressLookup: addressId === '' ? null : addressId,
            };

            const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                id, parentId, profilePicture, rank,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                paidForKit, joinDate, kitPurchaseDate,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                secondAddress, postCode, country, region, county, town, ...oldData
            } = this.data;

            const data: ProfileUpdateType = {
                ...oldData,
                addressLookup: addressSelect.addressId,
            };

            await this.validateInvitationForm({ data, businessDetails });

            if (Object.keys(this.formErrors).length === 0) {
                this.setLoading(true);
                this.setDisableBusinessDetails(false);

                let bankDetailsData = {
                    ...defaultBankDetails(),
                };

                const { bankAccountName, bankAccountNumber, bankSortCode } = this.bankDetails;

                const formattedBankSortCode = bankSortCode!.replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3');

                bankDetailsData = {
                    bankAccountName,
                    bankAccountNumber,
                    bankSortCode: formattedBankSortCode,
                };

                let businessDetailsData: null|BusinessDetailsType = null;

                if (businessDetails.businessAddressLookup !== '' && businessDetails.businessName !== '') {
                    businessDetailsData = businessDetails;
                }

                const result: UpdateProfileResultType = await ProfileRepository
                    .updateProfile({
                        ...data,
                        bankDetails: isHostess ? null : bankDetailsData,
                        businessDetails: businessDetailsData,
                    });

                const formattedData = ObjectProcessor.removeEmptyProperties(result.updateProfile);

                const businessDetailsRes = formattedData.businessDetails
                 || defaultBusinessDetails();

                if (businessDetailsRes && businessDetailsRes.businessAddress
                    && businessDetailsRes.businessName) {
                    this.setBusinessDetails(businessDetailsRes);
                    this.setDisableBusinessDetails(true);
                } else {
                    this.setDisableBusinessDetails(false);
                    this.setBusinessDetails(defaultBusinessDetails());
                }

                this.setProfileData(formattedData);
                // @ts-ignore
                componentsControl.showSuccessMessage({ message: this.store._vm.$t('message.updatedSuccessfully') });
                addressSelect.setAddress();
                addressSelect.clearOptions();
                profileBusinessAddressSelect.setAddress();
                profileBusinessAddressSelect.clearOptions();
            } else {
                this.displayFormErrorsTooltip();
            }
        } catch (e) {
            this.displayFormErrorsTooltip();
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(Profile);
