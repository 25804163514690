import { COUNTRY_SELECT_OPTIONS } from './constants';
import { BankingDetailsResolverType, LabelType } from './types';

export const gbLabels: (t) => LabelType = (t) => ({
    bankSortCode: t('common.bankingDetails.gb.bankSortCode'),
    bankAccountNumber: t('common.bankingDetails.gb.bankAccountNumber'),
});

export const ieLabels: (t) => LabelType = (t) => ({
    bankSortCode: t('common.bankingDetails.ie.bankSortCode'),
    bankAccountNumber: t('common.bankingDetails.ie.bankAccountNumber'),
});

export const resolveBankingDetails: (val: string|null, t)
 => BankingDetailsResolverType = (val, t) => {
     switch (val) {
     case COUNTRY_SELECT_OPTIONS.gb:
     case COUNTRY_SELECT_OPTIONS.ci:
     case COUNTRY_SELECT_OPTIONS.gg:
     case COUNTRY_SELECT_OPTIONS.im:
     case COUNTRY_SELECT_OPTIONS.nir:
         return {
             labels: gbLabels(t),
             displayStyle: 'UK',
         };
     case COUNTRY_SELECT_OPTIONS.fr:
     case COUNTRY_SELECT_OPTIONS.de:
     case COUNTRY_SELECT_OPTIONS.roi:
     case COUNTRY_SELECT_OPTIONS.it:
     case COUNTRY_SELECT_OPTIONS.esp:
     case COUNTRY_SELECT_OPTIONS.pl:
     case COUNTRY_SELECT_OPTIONS.ro:
     case COUNTRY_SELECT_OPTIONS.sk:
     case COUNTRY_SELECT_OPTIONS.ie:
     default:
         return {
             labels: ieLabels(t),
             displayStyle: 'OTHER',
         };
     }
 };
