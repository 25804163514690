import { BusinessDetailsUpdateType, ProfileUpdateType } from '@/api/graphQL/graphNodes/types';
import { validateEmail } from '@/utils/email-validation';
import { ValidationErrorType } from '../../types';

const profileFormValidation:
    (val: ProfileUpdateType, businessDetails: BusinessDetailsUpdateType, t) =>
    ValidationErrorType[] = (val, businessDetails, t) => {
        const errors: ValidationErrorType[] = [];

        if (!val.email) {
            errors.push({ key: 'email', val: t('validation.requiredField') });
        }

        if (!validateEmail(val.email)) {
            errors.push({ key: 'email', val: t('validation.invalidEmail') });
        }

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: t('validation.requiredField') });
        }

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: t('validation.requiredField') });
        }

        if (!val.phoneNumber) {
            errors.push({ key: 'phoneNumber', val: t('validation.requiredField') });
        }

        if (val.address === null && !val.addressLookup) {
            errors.push({ key: 'addressLookup', val: t('validation.requiredField') });
        }

        if (businessDetails && ((!!businessDetails.businessAddressLookup && businessDetails.businessName === '') || (
            !businessDetails.businessAddressLookup && businessDetails.businessName !== ''
        ))) {
            errors.push({ key: 'businessDetails.businessAddressLookup', val: t('validation.businessDetails') });
            errors.push({ key: 'businessDetails.businessName', val: t('validation.businessDetails') });
        }

        return errors;
    };

export default profileFormValidation;
