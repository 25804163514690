import {
    BankDetailsType,
    DriverLicenseType,
    PassportType,
    RegisterAmbassadorType,
    RegisterHostessType,
} from '@/api/graphQL/graphNodes/types';
import { COUNTRY_SELECT_OPTIONS } from '../constants';

const initialRegistrationBankDetailsData: () => BankDetailsType = () => ({
    bankAccountName: '',
    bankAccountNumber: '',
    bankSortCode: '',
});

const initialRegistrationData: () => RegisterAmbassadorType = () => ({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    repeatPassword: '',
    phoneNumber: '',
    birthDate: '',
    region: COUNTRY_SELECT_OPTIONS.gb,
    bankDetails: initialRegistrationBankDetailsData(),
    termsChecked: false,
    addressLookup: '',
});

const initialRegistrationPassportData: () => PassportType = () => ({
    passport: '',
});

const initialRegistrationDriverLicenseData: () => DriverLicenseType = () => ({
    drivingLicense: '',
});
const initialHostessRegistrationData: () => RegisterHostessType = () => ({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    repeatPassword: '',
    termsChecked: false,
    birthDate: '',
    phoneNumber: '',
    region: COUNTRY_SELECT_OPTIONS.gb,
    addressLookup: '',
});

export {
    initialRegistrationData, initialRegistrationPassportData,
    initialRegistrationDriverLicenseData, initialRegistrationBankDetailsData,
    initialHostessRegistrationData,
};
