
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import rank from '@/modules/Rank';
import nextRankResolver from '@/modules/Rank/services/nextRankResolver';
import dashboard from '@/modules/Dashboard';
import { RankType } from '@/api/graphQL/graphNodes/types';
import { rank as defaultRank } from '@/modules/Rank/defaults';
import Stats from '../Stats/index.vue';

@Component({
    components: {
        Stats,
    },
})
export default class RankContainer extends Vue {
    @Get(dashboard, 'data.rank') private rank!: RankType|null;

    @Get(rank) private ranks!: RankType[];

    @Get(dashboard) private activeTab!: string;

    get nextRank(): RankType {
        if (this.rank === null) {
            return defaultRank();
        }

        const nextRankItem = nextRankResolver(this.rank.power);

        return nextRankItem || defaultRank();
    }

    get nextRankTitle(): string {
        const { label } = this.nextRank;
        if (label !== '') {
            return label;
        }

        return '-';
    }

    tabChanged(val: string) {
        dashboard.setActiveTab(val);
    }
}
