
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import flaggedRegistration from '@/modules/Register/flaggedRegistration';
import { REGISTER_FLAGGED_DOCUMENT_TYPE } from '@/modules/Register/constants';
import { SelectOptionsType } from '@/components/select/types';
import FormErrorTooltip from '@/projectComponents/formErrorTooltip/index.vue';
import authenticator from '@/modules/Auth/services/authenticator';
import { ErrorType } from '@plumtreesystems/utils';
import system from '@/modules/System';
import DrivingLicense from './DrivingLicense/index.vue';
import Passport from './Passport/index.vue';

@Component({
    components: {
        DrivingLicense,
        Passport,
        FormErrorTooltip,
    },
})
export default class RegisterVerification extends Vue {
    @Sync(flaggedRegistration) private type!: string;

    @Get(flaggedRegistration) private loading!: boolean;

    @Get(flaggedRegistration) private formPassportErrors!: ErrorType;

    @Get(flaggedRegistration) private formDriverLicenseErrors!: ErrorType;

    @Get(flaggedRegistration) private displayTooltip!: boolean;

    @Get(system) private screenType!: boolean;

    get displayPassport(): boolean {
        return this.type === REGISTER_FLAGGED_DOCUMENT_TYPE.passport;
    }

    get typeOptions(): SelectOptionsType[] {
        return [
            {
                name: this.$t('common.passport'),
                value: REGISTER_FLAGGED_DOCUMENT_TYPE.passport,
            },
            {
                name: this.$t('common.drivingLicense'),
                value: REGISTER_FLAGGED_DOCUMENT_TYPE.driverLicense,
            },
        ];
    }

    get logged(): boolean {
        const { logged } = this.$route.query;
        return logged === 'true';
    }

    async handleRegister() {
        try {
            await flaggedRegistration.submitDocument(this.$route.query.token.toString());
            if (Object.keys(this.formPassportErrors).length === 0
             && Object.keys(this.formDriverLicenseErrors).length === 0) {
                if (!this.logged) {
                    authenticator.logout();
                } else {
                    this.$router.push({ name: 'dashboard' });
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    handleBackToApp() {
        this.$router.push({ name: 'dashboard' });
    }

    beforeDestroy() {
        flaggedRegistration.setDisplayTooltip(false);
    }

    mounted() {
        flaggedRegistration.setLoading(false);
        flaggedRegistration.setPassportFormData();
        flaggedRegistration.setDriverLicenseFormData();
    }
}
