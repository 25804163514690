import { EventInputType, HostessInvitationInputType, KnownHostessInvitationInputType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class CreateEventQuery extends
    AbstractQueryResource<CreateEventResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($event: EventCreateInput) {
            createEvent(event: $event) {
                id,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'createEvent';
    }
}

export type CreateEventParamsType = EventInputType & {
    hostessInvitation: null|HostessInvitationInputType|KnownHostessInvitationInputType,
};

export type CreateEventResultType = {
    createEvent: {
        id: string;
    };
}
