import { MilestoneType } from '@/api/graphQL/graphNodes/types';
// eslint-disable-next-line import/no-cycle
import { defaultEnrollee } from '@/modules/Calendar/defaults';
import { MILESTONE_KEY, MILESTONE_TYPE } from '@/modules/Dashboard/constants';
import { LEXIS_NEXIS_STATUS } from '@/modules/Register/constants';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import { ResolverEnrolleeType, ResolverEventType } from './types';

const dayTimeFormatWithoutTime = 'YYYY-MM-DD';

const currentDate = mockedDateManager.getCurrentDate(dateManager.getDateFormat());

export const defaultResolverEnrollee: () => ResolverEnrolleeType = () => ({
    ...defaultEnrollee(),
    totals: {
        gv: 0,
        spa_gv: 0,
        ov: 0,
        pv: 0,
    },
    parentId: null,
    directDownline: [],
    leaderLegs: [],
    risingStars: [],
    customers: [],
    ambassadorsCountsByActivityLevel: null,
    newAmbassadorsPersonal: 0,
    newAmbassadorsInCentralTeam: 0,
    newAmbassadorsOrganizational: 0,
    newCustomersInCentralTeam: 0,
    starterKit: 0,
    qualifiedLeaderBranchIds: [],
    qualifiedOrgLeaderCount: 0,
    qualifiedFrontlineLeadersCount: 0,
    activeOnFileDirectEnrollees: 0,
    id: '',
    payRankId: '',
    highestAchievedRankId: '',
    uplineLeaderId: null,
    personal: [],
    centralTeam: [],
    organisational: [],
    superStarterOneTotals: {
        pv: 0,
        gv: 0,
        ov: 0,
    },
    superStarterTwoTotals: {
        pv: 0,
        gv: 0,
        ov: 0,
    },
    vanityUrl: '',
    shopUrl: '',
    customerNumber: null,
    canRegister: true,
    lexisNexisStatus: LEXIS_NEXIS_STATUS.none,
    shadow: false,
    hidden: false,
    aOFStatus: null,
});

export const defaultResolverEvent: () => ResolverEventType = () => ({
    address: '',
    allowGuestsToInvite: false,
    ambassador: '',
    campaignDateFrom: '',
    campaignDateTo: '',
    canceled: false,
    closed: false,
    confirmed: false,
    description: '',
    eventDateFrom: '',
    eventDateTo: '',
    hostess: '',
    id: '',
    invitations: [],
    shopUrl: '',
    title: '',
    type: '',
});

export const defaultsMilestones: () => MilestoneType[] = () => [
    {
        type: MILESTONE_TYPE.quickStart,
        key: 'kit_boost_uk',
        category: 'kit_boost',
        achieved: true,
        achievedAt: '2022-05-16T23:59:59+00:00',
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
    {
        type: MILESTONE_TYPE.quickStart,
        key: 'kit_boost_non_uk',
        category: 'kit_boost',
        achieved: false,
        achievedAt: null,
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
    {
        type: MILESTONE_TYPE.quickStart,
        key: 'kit_refund_uk',
        category: 'kit_refund',
        achieved: false,
        achievedAt: null,
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(-10, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
    {
        type: MILESTONE_TYPE.quickStart,
        key: 'kit_refund_non_uk',
        category: 'kit_refund',
        achieved: false,
        achievedAt: null,
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(10, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
    {
        type: MILESTONE_TYPE.quickStart,
        key: 'business_boost_uk',
        category: 'business_boost',
        achieved: false,
        achievedAt: null,
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
    {
        type: MILESTONE_TYPE.quickStart,
        key: 'business_boost_non_uk',
        category: 'business_boost',
        achieved: false,
        achievedAt: null,
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(-10, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
    {
        type: MILESTONE_TYPE.quickStart,
        key: 'double_promotion',
        category: 'double_promotion',
        achieved: false,
        achievedAt: null,
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(10, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
    {
        type: MILESTONE_TYPE.bdp,
        key: 'bronze_2_tertile',
        category: 'bronze',
        achieved: false,
        achievedAt: null,
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(5, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
    {
        type: MILESTONE_TYPE.bdp,
        key: 'silver_2_tertile',
        category: 'silver',
        achieved: true,
        achievedAt: null,
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(10, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
    {
        type: MILESTONE_TYPE.bdp,
        key: 'gold_2_tertile',
        category: 'gold',
        achieved: true,
        achievedAt: null,
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(-5, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
    {
        type: MILESTONE_TYPE.travel,
        key: 'full_incentive_criteria',
        category: 'travel_incentive',
        achieved: false,
        achievedAt: null,
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
    {
        type: MILESTONE_TYPE.wildcard,
        key: MILESTONE_KEY.consistentLeaderWildcard,
        category: MILESTONE_KEY.consistentLeaderWildcard,
        achieved: false,
        achievedAt: null,
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(5, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
    {
        type: MILESTONE_TYPE.wildcard,
        key: MILESTONE_KEY.newLeaderWildcard,
        category: MILESTONE_KEY.newLeaderWildcard,
        achieved: false,
        achievedAt: null,
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(10, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
    {
        type: MILESTONE_TYPE.wildcard,
        key: MILESTONE_KEY.activityWildcard,
        category: MILESTONE_KEY.activityWildcard,
        achieved: false,
        achievedAt: null,
        unachievable: false,
        state: [],
        endDate: `${dateManager.getDateWithOffset(10, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
        startDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
    },
];
