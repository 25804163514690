
import { Component, Vue } from 'vue-property-decorator';
import underInspection from '@/modules/NoPermission/underInspection';
import { Get } from '@/utils/vuex-module-mutators';
import { TEXT_FIELD_DISPLAY_TYPE } from '@/components/textField/constants';
import { LabelType } from '@/modules/types';
import { resolveBankingDetails } from '@/modules/labels';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { BankDetailsType } from '@/api/graphQL/graphNodes/types';
import FormFieldTooltip from '@/projectComponents/formFieldTooltip/index.vue';
import profile from '@/modules/Profile';

@Component({
    components: {
        FormFieldTooltip,
    },
})
export default class NoPermissionUnderInspection extends Vue {
    @Get(underInspection) private loading!: boolean;

    @Get(profile, 'originalData.region') private region!: string|null;

    @Get(underInspection) private data!: BankDetailsType

    @Get(underInspection) private formErrors!: ErrorType;

    get iban(): string {
        return TEXT_FIELD_DISPLAY_TYPE.iban;
    }

    get sortCodeType(): string|null {
        const bankingDetails = resolveBankingDetails(this.region, this.$t);
        if (bankingDetails.displayStyle === 'UK') {
            return TEXT_FIELD_DISPLAY_TYPE.sortCode;
        }

        return null;
    }

    get labels(): LabelType {
        return resolveBankingDetails(this.region, this.$t).labels;
    }

    handleValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.data);
        underInspection.removeFormError(key);
        underInspection.setFormData(res);
    }

    async handleSubmit() {
        try {
            await underInspection.saveData();

            if (Object.keys(this.formErrors).length === 0) {
                this.$router.push({ name: 'dashboard' });
            }
        // eslint-disable-next-line no-empty
        } catch (e) {}
    }
}
