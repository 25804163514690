import { ValidationErrorType } from '@/modules/types';
import { validateEmail } from '@/utils/email-validation';
import { EventInviteType } from '../EventInvitation/types';

const eventInviteFormValidation: (form: EventInviteType, t) =>
    ValidationErrorType[] = (form, t) => {
        const errors: ValidationErrorType[] = [];

        if (!form.firstName) {
            errors.push({ key: 'firstName', val: t('validation.requiredField') });
        }
        if (!form.lastName) {
            errors.push({ key: 'lastName', val: t('validation.requiredField') });
        }
        if (!form.email) {
            errors.push({ key: 'email', val: t('validation.requiredField') });
        }

        if (!validateEmail(form.email)) {
            errors.push({ key: 'email', val: t('validation.invalidEmail') });
        }

        return errors;
    };

export { eventInviteFormValidation };
