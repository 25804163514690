
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import PersonalDetails from '@/projectComponents/personalDetails/index.vue';
import FormErrorTooltip from '@/projectComponents/formErrorTooltip/index.vue';
import myCustomers from '@/modules/MyCustomers/index';
import Skeleton from '@/projectComponents/skeleton/Profile/index.vue';
import { DownlineProfileType } from '@/api/graphQL/graphNodes/types';
import auth from '@/modules/Auth';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import addressSelect from '@/modules/AddressSelect';
import countries from '@/modules/Countries';
import { CountriesType } from '@/modules/Event/types';

@Component({
    components: {
        PersonalDetails,
        FormErrorTooltip,
        Skeleton,
    },
})
export default class EditCustomer extends Vue {
    @Get(myCustomers) private customer!: DownlineProfileType;

    @Get(myCustomers) private originalCustomer!: DownlineProfileType;

    @Get(myCustomers) private formErrors!: ErrorType;

    @Get(myCustomers) private offset!: number;

    @Get(myCustomers) private customerLoading!: boolean;

    @Get(myCustomers) private displayTooltip!: boolean;

    @Get(countries, 'loading') private countriesLoading!: CountriesType[];

    @Get(countries) private regions!: CountriesType[];

    get subsceneTitle() {
        if (this.customerLoading) {
            return this.$t('view.myCustomers.edit.titleLoading');
        }
        return this.$t('view.myCustomers.edit.title', {
            fullName: `${this.originalCustomer.firstName} ${this.originalCustomer.lastName}`,
        });
    }

    get address(): string {
        const {
            address, secondAddress, postCode,
            town, county, country,
        } = this.customer;

        let res = address;

        if (!!secondAddress && secondAddress !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${secondAddress}`;
        }

        if (!!town && town !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${town}`;
        }

        if (!!postCode && postCode !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${postCode}`;
        }

        if (!!county && county !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${county}`;
        }

        if (!!country && country !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${country}`;
        }

        return res;
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    async handleProfileUpdate() {
        const { id } = this.$route.params;
        const { offset } = this;
        try {
            await myCustomers.updateCustomer(id);

            if (Object.keys(this.formErrors).length) {
                myCustomers.getMyCustomers({ loadPage: true, offset });
            }
        // eslint-disable-next-line no-empty
        } finally {}
    }

    handleValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.customer);

        myCustomers.removeFormError(key);
        myCustomers.setCustomer(res);
    }

    handleClose() {
        myCustomers.clearFormErrors();
    }

    handleOpen() {
        const { id } = this.$route.params;
        myCustomers.getMyCustomer(id);
        countries.getRegions();
        addressSelect.resetData();
    }
}
