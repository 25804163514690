
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import dateManager from '@/utils/time';
import Calendar from './Calendar.vue';
import getConfig from '../../lib/date';
import { DATE_PICKER_CONFIG_MONTH } from '../../constants';

@Component({
    components: {
        Calendar,
    },
})
export default class DatePicker extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop() private startDate!: string;

    @Prop() private endDate!: string;

    @Prop({ default: false }) private singleDate!: boolean;

    @Prop({ default: false }) private disableArrow!: boolean;

    @Prop() private onChange!: Function;

    @Prop() private minDate!: string;

    @Prop() private maxDate!: string;

    private innerStartDate: string = '';

    private innerEndDate: string = '';

    private selectedDay: null = null;

    private currentYear: number = 0;

    private currentMonth: number = 1;

    private monthNow: number = 0;

    get currentMonthString() {
        const config = getConfig(this.$t, DATE_PICKER_CONFIG_MONTH);
        return config !== null ? config[this.currentMonth - 1] : '';
    }

    get resolveStartDate(): string {
        return this.startDate || dateManager.getCurrentDate();
    }

    get iconRightDisabled() {
        const currentDate = dateManager.getCurrentDate('YYYY-MM');
        const selectedMonth = this.currentMonth.toLocaleString('en-US', { minimumIntegerDigits: 2 });
        const selectedDay = `${this.currentYear}-${selectedMonth}`;

        return selectedDay >= currentDate;
    }

    get iconLeftDisabled() {
        const selectedMonth = this.currentMonth.toLocaleString('en-US', { minimumIntegerDigits: 2 });
        const selectedDay = `${this.currentYear}-${selectedMonth}`;

        return selectedDay <= '1920-01';
    }

    // @Watch('currentMonth')
    callOnChange() {
        const {
            currentMonth: month,
            currentYear: year,
            innerStartDate: startDate,
            innerEndDate: endDate,
            selectedDay,
        } = this;

        const returnData = {
            month, year, startDate, endDate, selectedDay,
        };
        if (this.$listeners.onChange) {
            this.$emit('onChange', returnData);
        }
        if (this.onChange) {
            this.onChange(returnData);
        }
    }

    @Watch('startDate')
    setInitialValues() {
        this.currentMonth = Number(dateManager.getDateTime(this.resolveStartDate, 'MM'));
        this.currentYear = Number(dateManager.getDateTime(this.resolveStartDate, 'YYYY'));
        this.monthNow = Number(dateManager.getCurrentDate('MM'));
        this.innerStartDate = this.resolveStartDate;
        this.innerEndDate = this.endDate || dateManager.getDateWithOffset(2, this.resolveStartDate);
    }

    beforeMount() {
        this.setInitialValues();
    }

    addMonth() {
        if (this.currentMonth === 12) {
            this.currentMonth = 1;
            this.currentYear += 1;
            return undefined;
        }

        this.currentMonth += 1;
        return this.currentMonth;
    }

    minusMonth(): void {
        if (this.currentMonth === 1) {
            this.currentMonth = 12;
            this.currentYear -= 1;
            return;
        }

        this.currentMonth -= 1;
    }

    handleOnChange(data) {
        const { startDate, endDate, selectedDay } = data;
        this.innerStartDate = startDate;

        this.innerEndDate = endDate;
        this.selectedDay = selectedDay;

        return this.callOnChange();
    }
}
