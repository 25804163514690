import { render, staticRenderFns } from "./drawerFab.vue?vue&type=template&id=64be3996"
import script from "./drawerFab.vue?vue&type=script&lang=ts"
export * from "./drawerFab.vue?vue&type=script&lang=ts"
import style0 from "./drawerFab.vue?vue&type=style&index=0&id=64be3996&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports