import dateManager from '@/utils/time';
import { RegisterHostessType, RegisterAmbassadorType, BankDetailsType } from '@/api/graphQL/graphNodes/types';
import { ValidationErrorType } from '../../types';

const bankDetailsValidation:
    (val: BankDetailsType, t) =>
    ValidationErrorType[] = (val, t) => {
        const errors: ValidationErrorType[] = [];

        if (!val.bankAccountName) {
            errors.push({ key: 'bankDetails.bankAccountName', val: t('validation.requiredField') });
        }

        if (!val.bankAccountNumber) {
            errors.push({ key: 'bankDetails.bankAccountNumber', val: t('validation.requiredField') });
        }

        if (!val.bankSortCode) {
            errors.push({ key: 'bankDetails.bankSortCode', val: t('validation.requiredField') });
        }

        return errors;
    };

const registrationFormValidation:
    (val: RegisterAmbassadorType, passwordCheck: boolean, t) =>
    ValidationErrorType[] = (val, passwordCheck, t) => {
        const errors: ValidationErrorType[] = [];
        const currentDate = dateManager.getCurrentDate('YYYY-MM-DD');
        const selectedDate = dateManager.getDateWithOffset(18, val.birthDate, 'YYYY-MM-DD', 'y');

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: t('validation.requiredField') });
        }

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: t('validation.requiredField') });
        }

        if (!val.password && passwordCheck) {
            errors.push({ key: 'plainPassword', val: t('validation.requiredField') });
        }

        if (!val.repeatPassword && passwordCheck) {
            errors.push({ key: 'repeatPassword', val: t('validation.requiredField') });
        }

        if (val.password !== val.repeatPassword && passwordCheck) {
            errors.push({ key: 'repeatPassword', val: t('validation.passwordMatch') });
        }

        if (!val.termsChecked) {
            errors.push({ key: 'termsChecked', val: t('validation.requiredField') });
        }

        if (!val.phoneNumber) {
            errors.push({ key: 'phoneNumber', val: t('validation.requiredField') });
        }

        if (!val.addressLookup) {
            errors.push({ key: 'addressLookup', val: t('validation.requiredField') });
        }

        if (!val.birthDate) {
            errors.push({ key: 'birthDate', val: t('validation.requiredField') });
        }

        if (dateManager.isBefore(currentDate, selectedDate)) {
            errors.push({ key: 'birthDate', val: t('validation.age18') });
        }

        // errors = [...errors, ...bankDetailsValidation(val.bankDetails)];

        return errors;
    };

const hostessRegistrationFormValidation:
    (val: RegisterHostessType, t) => ValidationErrorType[] = (val, t) => {
        const errors: ValidationErrorType[] = [];
        // const currentDate = dateManager.getCurrentDate('YYYY-MM-DD');
        // const selectedDate = dateManager.getDateWithOffset(18, val.birthDate, 'YYYY-MM-DD', 'y');

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: t('validation.requiredField') });
        }

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: t('validation.requiredField') });
        }

        if (!val.password) {
            errors.push({ key: 'plainPassword', val: t('validation.requiredField') });
        }

        if (!val.repeatPassword) {
            errors.push({ key: 'repeatPassword', val: t('validation.requiredField') });
        } else if (val.password !== val.repeatPassword) {
            errors.push({ key: 'repeatPassword', val: t('validation.passwordMatch') });
        }

        if (!val.termsChecked) {
            errors.push({ key: 'termsChecked', val: t('validation.requiredField') });
        }

        // if (!val.birthDate) {
        //     errors.push({ key: 'birthDate', val: t('validation.requiredField') });
        // } else if (dateManager.isBefore(currentDate, selectedDate)) {
        //     errors.push({ key: 'birthDate', val: t('validation.age18') });
        // }

        return errors;
    };

export { registrationFormValidation, hostessRegistrationFormValidation, bankDetailsValidation };
