import { CreateEventParamsType } from '@/api/graphQL/graphNodes/CreateEventQuery';
import dateManager from '@/utils/time';
import { descriptionLengthValidation } from './fieldUpdateValidations';
import { EventErrorType, EventFormType } from '../types';

const eventInMinimumTimePeriod: (from: string, to: string) => boolean = (from, to) => dateManager.isBefore(dateManager.getDateWithOffset(59, from, dateManager.getDayTimeFormat(), 'minute'), to);

const reusedValidation: (val: Partial<CreateEventParamsType>, t)
=> EventErrorType[] = (val, t) => {
    const errors: EventErrorType[] = [];

    if (!val.title) {
        errors.push({ key: 'title', val: t('validation.requiredField') });
    }

    if (val.shippingAddress && !val.shippingAddress.title) {
        errors.push({ key: 'shippingAddress.title', val: t('validation.requiredField') });
    }

    if (val.shippingAddress && !val.shippingAddress.firstName) {
        errors.push({ key: 'shippingAddress.firstName', val: t('validation.requiredField') });
    }

    if (val.shippingAddress && !val.shippingAddress.lastName) {
        errors.push({ key: 'shippingAddress.lastName', val: t('validation.requiredField') });
    }

    if (val.shippingAddress && !val.shippingAddress.phoneNumber) {
        errors.push({ key: 'shippingAddress.phoneNumber', val: t('validation.requiredField') });
    }

    if (val.campaignDateFrom && val.campaignDateTo
        && !eventInMinimumTimePeriod(val.campaignDateFrom, val.campaignDateTo)) {
        errors.push({ key: 'campaignDateTo', val: t('validation.eventMinimumTimeFrame') });
    }

    if (val.eventDateFrom && val.eventDateTo
        && !eventInMinimumTimePeriod(val.eventDateFrom, val.eventDateTo)) {
        errors.push({ key: 'eventDateTo', val: t('validation.eventMinimumTimeFrame') });
    }

    if (val.description) {
        const err = descriptionLengthValidation(val.description, t);

        if (err) {
            errors.push({ key: 'description', val: err });
        }
    }

    return errors;
};

const formEventValidation: (val: Partial<CreateEventParamsType>, t)
 => EventErrorType[] = (val, t) => {
     const errors: EventErrorType[] = [
         ...reusedValidation(val, t),
     ];

     if (!val.addressLookup && !val.videoUrl) {
         errors.push({ key: 'addressLookup', val: t('validation.emptyAddressAndVideoUrl') });
         errors.push({ key: 'videoUrl', val: t('validation.emptyAddressAndVideoUrl') });
     }

     if (val.shippingAddress && !val.shippingAddress.addressLookup) {
         errors.push({ key: 'shippingAddress.addressLookup', val: t('validation.requiredField') });
     }

     return errors;
 };

const formEditValidation: (val: Partial<EventFormType>, t)
 => EventErrorType[] = (val, t) => {
     const errors: EventErrorType[] = [
         ...reusedValidation(val, t),
     ];

     if (!val.addressLookup && !val.videoUrl && !val.address) {
         errors.push({ key: 'addressLookup', val: t('validation.emptyAddressAndVideoUrl') });
         errors.push({ key: 'videoUrl', val: t('validation.emptyAddressAndVideoUrl') });
     }

     return errors;
 };

export { formEventValidation, formEditValidation };
