
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class orderHeaderTitle extends Vue {
    @Prop() private customerFullName!: string;

    @Prop() private currencyPrefix!: string;

    @Prop() private orderDate!: string;

    @Prop({ default: false }) private showCustomerName!: boolean;

    @Prop() private value!: string;

    @Prop() private ps!: string;

    @Prop() private orderNo!: string;

    @Prop() private referenceId!: string;

    @Prop() private screenType!: string;

    @Prop() private type!: string|null;

    @Prop() private isReturn!: boolean;

    get dateElementClasses() {
        return [
            'pts-layout-item',
            this.showCustomerName ? 'pts-size-20' : 'pts-size-30',
        ];
    }

    get valueElementClasses() {
        return [
            'OrdersListItem__OrderValues',
            'pts-size-25',
        ];
    }

    get numberElementClasses() {
        return [
            'OrdersListItem__OrderNo',
            'pts-layout-item',
            this.showCustomerName ? 'pts-size-25' : 'pts-size-30',
        ];
    }

    get elementClasses() {
        return [
            'OrdersListItem__Header',
            'pts-layout',
            'pts-size-100',
            { OrdersListItem__HeaderWithName: this.showCustomerName },
        ];
    }

    get psClasses() {
        return [
            'OrdersListItem__OrderValueBg',
            'OrdersListItem__OrderValueBgMargin',
            { 'OrdersListItem__OrderValueBg--negative': Number(this.ps) < 0 },
        ];
    }

    get isMobile() {
        return this.screenType === 'mobile';
    }

    get orderType(): string {
        const { type } = this;
        if (type === null) {
            return '-';
        }

        return type.charAt(0).toUpperCase() + type.slice(1);
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }

    formattedPs(): string {
        const ps = Number(this.ps);

        if (ps >= 0) {
            return this.$t('projectComponents.orderHeaderTitle.psPositive', { ps: this.format2DecimalPoints(ps) });
        }
        return this.$t('projectComponents.orderHeaderTitle.psNegative', { ps: this.format2DecimalPoints(Math.abs(ps)) });
    }
}
