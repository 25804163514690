
import { Vue, Component, Prop } from 'vue-property-decorator';
import requirementRisingStars from '@/modules/RisingStars/requirementRisingStars';
import requirementRisingStarsLeg from '@/modules/RisingStars/requirementRisingStarsLeg';
import nextRankResolver from '@/modules/Rank/services/nextRankResolver';
import { CGet, Get } from '@/utils/vuex-module-mutators';
import { GenealogyEnrolleeType, RankType, RisingStarsType } from '@/api/graphQL/graphNodes/types';
import RisingStarsStatusTooltipContent from '@/projectComponents/risingStarsStatusTooltipContent/index.vue';
import { LegType } from '@/modules/types';
import calculateStatus from './services/calculateStatus';

@Component({
    components: {
        RisingStarsStatusTooltipContent,
        // Because of recursion vue needs to looped component like lambda function
        // fix src: https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to
        LegItem: () => import('@/projectComponents/enrolleeLegItem/Item/index.vue'),
    },
})
export default class RankRequirementAmbassadorLegDetailsItem extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop() private ranks!: RankType[];

    @CGet(requirementRisingStarsLeg) private data!: GenealogyEnrolleeType;

    @CGet(requirementRisingStarsLeg) private loading!: boolean;

    @Get(requirementRisingStars) private expandedRisingStars!: string[];

    get nextRank(): RankType|null {
        return nextRankResolver(this.data.rank.power);
    }

    get teamAmbassador(): RankType|undefined {
        return this.ranks
            .find((rankItem: RankType) => rankItem.label === 'Teamleiter');
    }

    get calculatedStatus() {
        return calculateStatus(this.data as LegType);
    }

    getAmbassadorLegsExpanded(leg: RisingStarsType) {
        return !!this.expandedRisingStars.find((item) => item === leg.id);
    }

    handleExpandedContentToggle(val: string) {
        requirementRisingStars.toggleExpandedList(val);
    }
}
