import { defaultShippingAddress } from '@/modules/Event/defaults';
import { ResolverEventShippingAddressType, ResolverEventType } from '../types';
import { enrollee } from './enrollee';

const defaultResolverShippingAddress: () => ResolverEventShippingAddressType = () => ({
    id: '',
    ...defaultShippingAddress(),
});

const eventShippingAddresses: ResolverEventShippingAddressType[] = [
    {
        ...defaultResolverShippingAddress(),
        id: '0',
        address: '33 Emerson Road',
        city: 'Berlin',
        country: 'GB',
        postcode: 'YO5 1RJ',
        phoneNumber: '077 8822 9045',
    },
    {
        ...defaultResolverShippingAddress(),
        id: '1',
        address: '690 Poppy Hollow Hunterborough',
        city: 'Dortmund',
        country: 'GB',
        postcode: 'CT6 9AR',
        phoneNumber: '(08241) 148198',
    },
    {
        ...defaultResolverShippingAddress(),
        id: '2',
        address: '6 Murray Unions Saundersview',
        city: 'Berlin',
        country: 'GB',
        postcode: 'GL1 2SZ',
        phoneNumber: '0951256688',
    },
    {
        ...defaultResolverShippingAddress(),
        id: '3',
        address: '9 Harrison Springs, Carolinebury',
        city: 'Schlat',
        country: 'GB',
        postcode: 'L15 6UE',
        phoneNumber: '02220 477532',
    },
    {
        ...defaultResolverShippingAddress(),
        id: '4',
        address: '3 Bruce Fork Saunderstown',
        city: 'Schlat',
        country: 'GB',
        postcode: 'TR10 8QN',
        phoneNumber: '(05405) 95690',
    },
    {
        ...defaultResolverShippingAddress(),
        id: '5',
        address: '53 Scott Road West, Mollyport',
        city: 'Berlin',
        country: 'GB',
        postcode: 'LU2 7LJ',
        phoneNumber: '+44(0)9483 295515',
    },
    {
        ...defaultResolverShippingAddress(),
        id: '6',
        address: 'Flat 84k Kelly Spring Butlerport',
        city: 'Berlin',
        country: 'GB',
        postcode: 'SE1 7DB',
        phoneNumber: '(0037) 4727244',
    },
    {
        ...defaultResolverShippingAddress(),
        id: '7',
        address: 'Studio 80 Yasmine Rest Katieshire',
        city: 'Schlat',
        country: 'GB',
        postcode: 'SN2 7TE',
        phoneNumber: '(0381) 0539382',
    },
    {
        ...defaultResolverShippingAddress(),
        id: '8',
        address: '9 Victoria Lodge Isabellaville',
        city: 'Berlin',
        country: 'GB',
        postcode: 'CO12 3SQ',
        phoneNumber: '0803 3783442',
    },
    {
        ...defaultResolverShippingAddress(),
        id: '9',
        address: '2 Stewart Spurs West Charliehaven',
        city: 'Schlat',
        country: 'GB',
        postcode: 'DH3 2NB',
        phoneNumber: '+44(0)423028327',
    },
];

const eventShippingAddress: (event: ResolverEventType) =>
ResolverEventShippingAddressType = (event) => {
    const el = eventShippingAddresses.find((item) => item.id === event.id);

    if (el) {
        const hostId = event.hostess || event.ambassador;
        const hostess = enrollee(hostId);

        return {
            ...el,
            title: 'ms',
            firstName: hostess.firstName,
            lastName: hostess.lastName,
        };
    }

    return defaultResolverShippingAddress();
};

export { eventShippingAddress };
