

import { Component, Vue } from 'vue-property-decorator';
import { Sync, Get } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import calendar from '@/modules/Calendar';
import system from '@/modules/System';
import { CALENDAR_TIME_FRAME_OPTIONS } from '@/modules/Calendar/constants';
import { calendarTimeFrameOptions } from '@/modules/Calendar/options';

@Component
export default class CalendarTimeFrameSelect extends Vue {
    @Sync(calendar) calendarTimeFrame!: string;

    @Get(calendar) isLoading!: boolean;

    @Get(system) screenType!: string;

    handleTimeFrameChange(data: string) {
        calendar.handleTimeFrameChange(data);
    }

    get selectMonthType(): string {
        return CALENDAR_TIME_FRAME_OPTIONS.month;
    }

    get selectWeekType(): string {
        return CALENDAR_TIME_FRAME_OPTIONS.week;
    }

    get selectDayType(): string {
        return CALENDAR_TIME_FRAME_OPTIONS.day;
    }

    get calendarTimeFrameOptions(): SelectOptionsType[] {
        return calendarTimeFrameOptions(this.$t);
    }
}
