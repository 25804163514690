import { validateEmail } from '@/utils/email-validation';
import { RegistrationFormType } from '../types';
import { ValidationErrorType } from '../../types';

const registrationFormValidation:
    (val: RegistrationFormType, t) => ValidationErrorType[] = (val, t) => {
        const errors: ValidationErrorType[] = [];

        if (!val.email) {
            errors.push({ key: 'email', val: t('validation.requiredField') });
        }

        if (!validateEmail(val.email)) {
            errors.push({ key: 'email', val: t('validation.invalidEmail') });
        }

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: t('validation.requiredField') });
        }

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: t('validation.requiredField') });
        }

        return errors;
    };

export default registrationFormValidation;
