import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetTermsAndConditionsResultType } from '@/api/graphQL/graphNodes/GetTermsAndConditionsQuery';
import { AssetType } from '@/api/graphQL/graphNodes/types';
import noPermissionRepository from './services/noPermissionRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'noPermission',
})
@AutoMutations
export class NoPermission extends VuexModule {
    private paidForKit: boolean|null = null;

    private loading: boolean = false;

    private visibleLoaderLoading: boolean = false;

    private termsAndConditions: AssetType|null = null;

    private termsAndConditionsLoaded: boolean = false;

    private termsAndConditionsAgreed: boolean = false;

    private errors: ErrorType = {};

    private tncErrors: string = '';

    private name: string = '';

    get getPaidForKit() {
        return this.paidForKit;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setPermissionErrors(val: ErrorType) {
        this.errors = { ...this.errors, ...val };
    }

    @Mutation
    public clearErrors() {
        this.errors = {};
    }

    @Mutation
    public setTermsAndConditions(val: AssetType|null) {
        this.termsAndConditions = val;
    }

    @Mutation
    public setTermsAndConditionsLoaded(val: boolean) {
        this.termsAndConditionsLoaded = val;
    }

    @Mutation
    public setPaidForKit(val: boolean|null) {
        this.paidForKit = val;
    }

    @Mutation
    public setTermsAndConditionsAgreed(val: boolean) {
        this.termsAndConditionsAgreed = val;
    }

    @Mutation
    public setVisibleLoaderLoading(val: boolean) {
        this.visibleLoaderLoading = val;
    }

    @Mutation
    public setTNCError(val: string) {
        this.tncErrors = val;
    }

    @Mutation
    public setName(val: string) {
        this.name = val;
    }

    @Action()
    public clearData() {
        this.setLoading(false);
        this.setTermsAndConditions(null);
        this.setTermsAndConditionsLoaded(false);
        this.setPaidForKit(null);
        this.setVisibleLoaderLoading(false);
        this.setTNCError('');
    }

    @Action()
    public async loadTermsAndConditions() {
        try {
            this.setLoading(true);
            const res: GetTermsAndConditionsResultType = await noPermissionRepository
                .getTermsAndConditions();
            this.setTermsAndConditions(res.termsAndConditions
                ? res.termsAndConditions.content : null);
            this.setTermsAndConditionsLoaded(true);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async getProfileInfo() {
        try {
            this.setLoading(true);
            const res = await noPermissionRepository.getProfileInfo();
            this.setName(`${res.profile.firstName} ${res.profile.lastName}`);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async updateTermsAndConditions() {
        try {
            if (!this.termsAndConditionsAgreed) {
                // @ts-ignore
                this.setTNCError(this.store._vm.$t('validation.requiredField'));
            } else {
                this.setTNCError('');
                this.setVisibleLoaderLoading(true);
                await noPermissionRepository.updateTermsAndConditions();
            }
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        } finally {
            this.setVisibleLoaderLoading(false);
        }
    }
}

export default getModule(NoPermission);
