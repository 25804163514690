

import { Component, Vue } from 'vue-property-decorator';
import { Sync, Get } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import calendar from '@/modules/Calendar';
import system from '@/modules/System';
import { CALENDAR_EVENTS_FINISH_STATUS_OPTIONS } from '@/modules/Calendar/constants';
import { calendarEventsStatusOptions } from '@/modules/Calendar/options';

@Component
export default class DisplayedEventsTypeSelect extends Vue {
    @Sync(calendar) eventsStatus!: string;

    @Get(calendar) isLoading!: boolean;

    @Get(system) screenType!: string;

    handleChange(data: string) {
        calendar.handleDisplayEventChange(data);
    }

    get calendarEventsStatusOptions(): SelectOptionsType[] {
        return calendarEventsStatusOptions(this.$t);
    }

    get selectNotDoneType(): string {
        return CALENDAR_EVENTS_FINISH_STATUS_OPTIONS.notDone;
    }

    get selectDoneType(): string {
        return CALENDAR_EVENTS_FINISH_STATUS_OPTIONS.done;
    }

    get selectAllType(): string {
        return CALENDAR_EVENTS_FINISH_STATUS_OPTIONS.all;
    }
}
