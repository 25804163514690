import { AmbassadorInvitationType } from '@/api/graphQL/graphNodes/types';
import { ValidationErrorType } from '@/modules/types';
import { validateEmail } from '@/utils/email-validation';

const emailInviteFormValidation: (form: AmbassadorInvitationType, t)
    => ValidationErrorType[] = (form, t) => {
        const errors: ValidationErrorType[] = [];

        if (!form.email) {
            errors.push({ key: 'email', val: t('validation.requiredField') });
        }

        if (!validateEmail(form.email)) {
            errors.push({ key: 'email', val: t('validation.invalidEmail') });
        }

        if (!form.lastName) {
            errors.push({ key: 'lastName', val: t('validation.requiredField') });
        }

        if (!form.firstName) {
            errors.push({ key: 'firstName', val: t('validation.requiredField') });
        }

        return errors;
    };

export { emailInviteFormValidation };
