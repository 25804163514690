
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import profile from '@/modules/Profile';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { BankDetailsType } from '@/api/graphQL/graphNodes/types';
import { LabelType } from '@/modules/types';
import FormFieldTooltip from '@/projectComponents/formFieldTooltip/index.vue';
import { TEXT_FIELD_DISPLAY_TYPE } from '@/components/textField/constants';
import { resolveBankingDetails } from '@/modules/labels';

@Component({
    components: {
        FormFieldTooltip,
    },
})
export default class ProfileBankDetails extends Vue {
    @Get(profile) private bankDetails!: BankDetailsType;

    @Get(profile) private formErrors!: ErrorType;

    @Prop() private region!: string;

    get iban(): string {
        return TEXT_FIELD_DISPLAY_TYPE.iban;
    }

    get sortCodeType(): string|null {
        const bankingDetails = resolveBankingDetails(this.region, this.$t);
        if (bankingDetails.displayStyle === 'UK') {
            return TEXT_FIELD_DISPLAY_TYPE.sortCode;
        }

        return null;
    }

    get labels(): LabelType {
        return resolveBankingDetails(this.region, this.$t).labels;
    }

    handleBankValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.bankDetails);
        profile.removeFormError(key);
        profile.setBankDetails(res);
    }
}
