// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class UploadProfilePictureQuery
    extends AbstractQueryResource<UploadProfilePictureResultType> {
    public getQuery(): string {
        return `mutation ${this.getName()}($uploadedFile: Upload) {
            uploadProfilePicture(uploadedFile: $uploadedFile) {
                link,
                context {
                    metric,
                    value
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'uploadProfilePicture';
    }
}

export type UploadProfilePictureResultType = {
    uploadProfilePicture: UploadProfilePictureType;
}

export type UploadProfilePictureType = {
    link: string;
    context: {
        metric: string;
        value: string;
    }[];
};
