import { CustomerUpdateProfileType } from '@/api/graphQL/graphNodes/types';
import { ValidationErrorType } from '../../types';

const customerFormValidation:
    (val: CustomerUpdateProfileType, t) =>
    ValidationErrorType[] = (val, t) => {
        const errors: ValidationErrorType[] = [];

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: t('validation.requiredField') });
        }

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: t('validation.requiredField') });
        }

        return errors;
    };

export default customerFormValidation;
