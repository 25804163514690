
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class AoFStatus extends Vue {
    @Prop() private val!: string|null;

    get classes() {
        return [
            'Status__Element',
            'AoFStatus__Element',
            this.calculateContainerColor(this.val),
        ];
    }

    get displayVal(): string {
        return this.val || '';
    }

    calculateContainerColor(key: string|null): string {
        switch (key) {
        case 'N3':
        case 'N4':
        case 'N5':
            return 'Status__Element--insufficient';
        case 'N':
        case 'N1':
        case 'N2':
            return 'Status__Element--almost';
        case 'Y':
            return 'Status__Element--fulfilled';
        case null:
            return 'Status__Element--disabled';

        default:
            return '';
        }
    }
}
