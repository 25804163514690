import { LegType } from '@/modules/types';
import resolveStatusType from '@/projectComponents/enrolleeLegItem/Item/services/resolveStatusType';

const calculateStatus: (data: LegType)
 => string = (data) => {
     const pv = data.stats.find((stat) => stat.metric === 'pv');
     if (pv) {
         const value = Number(pv.value);
         const percentage = value >= 150 ? 100 : (value * 100) / 150;
         return resolveStatusType(percentage);
     }

     return resolveStatusType(0);
 };

export default calculateStatus;
