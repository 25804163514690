
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import profile from '@/modules/Profile';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { BusinessDetailsType } from '@/api/graphQL/graphNodes/types';
import BaseAddressSelect from '@/projectComponents/addressSelect/base.vue';
import profileBusinessAddressSelect from '@/modules/ProfileBusinessAddressSelect';
import profileAddressSelectOption from '@/modules/ProfileBusinessAddressSelect/addressOption';
import { SelectOptionsType } from '@/components/select/types';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';

@Component({
    components: {
        BaseAddressSelect,
        PropertyInfoDisplay,
    },
})
export default class ProfileBusinessDetails extends Vue {
    @Get(profile) private businessDetails!: BusinessDetailsType;

    @Get(profile) private formErrors!: ErrorType;

    @Get(profile) private disableBusinessDetails!: boolean;

    @Get(profileBusinessAddressSelect) optionsIds!: string[];

    @Sync(profileBusinessAddressSelect) private searchWord!: string;

    @Get(profileBusinessAddressSelect) private loading!: boolean;

    @Get(profileBusinessAddressSelect) private resultSize!: number;

    get options(): SelectOptionsType[] {
        return this.optionsIds.map((item) => {
            const { data, id } = profileAddressSelectOption.collection[item];
            const description = data!.description !== '' ? `, ${data!.description}` : '';

            return {
                name: `${data!.text}${description}`,
                value: id!,
            };
        });
    }

    handleBusinessValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.businessDetails);
        profile.removeFormError(key);
        profile.setBusinessDetails(res);
    }

    setLabel(val: SelectOptionsType) {
        profileBusinessAddressSelect.setAddress(String(val.value));
        profileBusinessAddressSelect.setSearchWord(val.name);
    }

    loadOptions() {
        profileBusinessAddressSelect.findAddresses();
    }
}
