import Vue from 'vue';
import {
    MdIcon, MdMenu, MdList, MdTabs, MdButton, MdTooltip, MdElevation,
} from 'vue-material/dist/components';
import 'quill/dist/quill.snow.css';
import 'vue-material/dist/vue-material.min.css';
import PortalVue from 'portal-vue';
import Clipboard from 'v-clipboard';
import VueSocialSharing from 'vue-social-sharing';
import CommonComponents from '@/components';
import VueCoreVideoPlayer from 'vue-core-video-player';
import VueCropper from 'vue-cropperjs';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueGtag from 'vue-gtag';
import env from '@/environment';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import translations from './translations';

Object.entries(CommonComponents).forEach((item: any) => {
    Vue.component(
        item[0],
        item[1],
    );
});
Vue.component(VueCropper);

Vue.config.productionTip = false;

Vue.use(MdIcon);
Vue.use(MdMenu);
Vue.use(MdList);
Vue.use(MdTabs);
Vue.use(MdButton);
Vue.use(MdTooltip);
Vue.use(PortalVue);
Vue.use(MdElevation);
Vue.use(Clipboard);
Vue.use(VueSocialSharing);
// for video files
Vue.use(VueCoreVideoPlayer);
Vue.use(VueReCaptcha, {
    siteKey: env.VUE_APP_RECAPTCHA_SITE_KEY,
    loaderOptions: {
        useRecaptchaNet: true,
    },
});

Vue.use(I18NextVue, { i18next });

Vue.use(
    VueGtag,
    {
        config: {
            id: env.VUE_APP_GTAG,
        },
    },
    router,
);

i18next.on('initialized', () => {
    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');
});

i18next.init({
    debug: env.VUE_APP_DEV === 'true',
    lng: 'en',
    resources: translations,
});
