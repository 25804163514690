import Dashboard from '@/views/Dashboard/index.vue';
import Achievement from '@/views/Dashboard/Rankings/AwardsList/index.vue';
import DownlineExplorer from '@/views/DownlineExplorer/index.vue';
import LeaderLegs from '@/views/LeaderLegs/index.vue';
import IncomeSummary from '@/views/IncomeSummary/index.vue';
import Orders from '@/views/Orders/index.vue';
import CreateEvent from '@/views/Events/Create/index.vue';
import CreateEventType from '@/views/Events/Create/Type/index.vue';
import CreateEventHostess from '@/views/Events/Create/Hostess/index.vue';
import EditEvent from '@/views/Events/Edit/index.vue';
import EventInvite from '@/views/EventInvite/index.vue';
import EventInvitationView from '@/views/Events/InvitationView/index.vue';
import Events from '@/views/Events/index.vue';
import Invite from '@/views/Invite/index.vue';
import UIComponents from '@/views/UIComponents/index.vue';
import Profile from '@/views/Profile/index.vue';
import NewStarters from '@/views/NewStarters/index.vue';
import NewStartersOrders from '@/views/NewStarters/Orders/index.vue';
import RisingStars from '@/views/RisingStars/index.vue';
import Auth from '@/views/Auth/index.vue';
import Login from '@/views/Login/index.vue';
import Logout from '@/views/Auth/logout.vue';
import AuthError from '@/views/Auth/authError.vue';
import MyCustomers from '@/views/MyCustomers/index.vue';
import CustomerOrders from '@/views/MyCustomers/Orders/index.vue';
import EditCustomer from '@/views/MyCustomers/EditCustomer/index.vue';
import HelpAndSupport from '@/views/HelpAndSupport/index.vue';
import UIActionMenus from '@/views/UIComponents/UIActionMenus/index.vue';
import UIButtons from '@/views/UIComponents/UIButtons/index.vue';
import UIClipboards from '@/views/UIComponents/UiClipboards/index.vue';
import UIDatePickers from '@/views/UIComponents/UIDatePickers/index.vue';
import UIDialogs from '@/views/UIComponents/UIDialogs/index.vue';
import UIExpansion from '@/views/UIComponents/UIExpansion/index.vue';
import UIFileArea from '@/views/UIComponents/UIFileArea/index.vue';
import UIFiles from '@/views/UIComponents/UIFiles/index.vue';
import UIIconButtons from '@/views/UIComponents/UIIconButtons/index.vue';
import UIInlineEdits from '@/views/UIComponents/UIInlineEdits/index.vue';
import UILinkButtons from '@/views/UIComponents/UILinkButtons/index.vue';
import UILoader from '@/views/UIComponents/UILoader/index.vue';
import UIMap from '@/views/UIComponents/UIMap/index.vue';
import UIPaginationHydra from '@/views/UIComponents/UIPaginationHydra/index.vue';
import UIPagination from '@/views/UIComponents/UIPagination/index.vue';
import UIProgressBar from '@/views/UIComponents/UIProgressBar/index.vue';
import UIProgressBar2 from '@/views/UIComponents/UIProgressBar2/index.vue';
import UIProgressBarCircular from '@/views/UIComponents/UIProgressBarCircular/index.vue';
import UIRadioOptions from '@/views/UIComponents/UIRadioOptions/index.vue';
import UISearchSelects from '@/views/UIComponents/UISearchSelects/index.vue';
import UISelects from '@/views/UIComponents/UISelects/index.vue';
import UISnackbars from '@/views/UIComponents/UISnackbars/index.vue';
import UISubScene from '@/views/UIComponents/UISubScene/index.vue';
import UITabs from '@/views/UIComponents/UITabs/index.vue';
import UITags from '@/views/UIComponents/UITags/index.vue';
import UITextEditors from '@/views/UIComponents/UITextEditors/index.vue';
import UITextFields from '@/views/UIComponents/UITextFields/index.vue';
import UITimeZoneSelects from '@/views/UIComponents/UITimeZoneSelects/index.vue';
import UITooltips from '@/views/UIComponents/UITooltips/index.vue';
import UIWarningButtons from '@/views/UIComponents/UIWarningButtons/index.vue';
import EventView from '@/views/Events/View/index.vue';
import CreateEventView from '@/views/Events/Create/Event/index.vue';
import KnownHostessSelect from '@/views/Events/Create/Hostess/knownHostessSelect.vue';
import ErrorPage from '@/views/ErrorPage/index.vue';
// import BusinessShape from '@/views/BusinessShape/index.vue';
import ActiveBusinessShape from '@/views/BusinessShape/ActiveAmbassadors/index.vue';
// import QualifiedBusinessShape from '@/views/BusinessShape/QualifiedAmbassadors/index.vue';
// import BecomeAmbassador from '@/views/BecomeAmbassador/index.vue';
import EndImpersonation from '@/views/Impersonate/endImpersonation.vue';
import NoPermission from '@/views/NoPermission/index.vue';
import Register from '@/views/Register/index.vue';
import RegisterVerification from '@/views/RegisterVerification/index.vue';
import SendRegistration from '@/views/SendRegistration/index.vue';
import AfterRegistration from '@/views/SendRegistration/AfterRegistration.vue';
// import HostessRegistration from '@/views/Register/HostessRegistration/index.vue';
import Reports from '@/views/Reports/index.vue';
// import CustomerRegistration from '@/views/Register/CustomerRegistration/index.vue';
import StoreCredit from '@/views/StoreCredit/index.vue';
import MyReports from '@/views/MyReports/index.vue';
import env from '@/environment';
import impersonate from '@/modules/Impersonation';
import protectedRoute from './services/protectedRoute';

export type RouteType = {
    path: string,
    name: string,
    component?: any,
    menu?: boolean,
    icon?: string,
    permissions?: string[],
    beforeEnter?: (to, from, next) => void,
    children?: RouteType[],
    disabled?: boolean,
    props?: any,
    title?: string,
    subTitle?: string,
}

const unlockedAfterStage: (stage: number) => boolean = (stage) => Number(env.VUE_APP_STAGE) > stage;

const impersonating: () => boolean = () => impersonate.impersonating;

const isDevelopmentEnvironment: () => boolean = () => env.VUE_APP_DEV === 'true';

const routes = () => {
    const routeArr: RouteType[] = [
        {
            path: '/auth',
            name: 'auth',
            component: Auth,
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
        },
        {
            path: '/auth-error',
            name: 'authError',
            component: AuthError,
        },
        {
            path: '/',
            name: 'dashboard',
            component: Dashboard,
            beforeEnter: protectedRoute([], ['ROLE_HOSTESS', 'ROLE_CUSTOMER']),
            children: [
                {
                    path: '/achievement/:type',
                    name: 'achievement',
                    component: Achievement,
                    beforeEnter: protectedRoute([], ['ROLE_HOSTESS', 'ROLE_CUSTOMER']),
                },
            ],
        },
        {
            path: '/my-downline',
            name: 'myDownline',
            component: DownlineExplorer,
            beforeEnter: protectedRoute([], ['ROLE_HOSTESS', 'ROLE_CUSTOMER']),
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
        },
        {
            path: '/register-additional-details',
            name: 'registerAdditionalDetails',
            component: RegisterVerification,
        },
        // {
        //     path: '/host-registration',
        //     name: 'hostessRegistration',
        //     component: HostessRegistration,
        // },
        // TODO uncomment route to enable customer registration
        // {
        //     path: '/customer-registration',
        //     name: 'customerRegistration',
        //     component: CustomerRegistration,
        // },
        {
            path: '/send-registration/:vanityUrl',
            name: 'sendRegistration',
            component: SendRegistration,
        },
        {
            path: '/after-registration/:vanityUrl',
            name: 'afterRegistration',
            component: AfterRegistration,
        },
        {
            path: '/business-shape',
            name: 'myBusinessShape',
            component: ActiveBusinessShape,
            beforeEnter: protectedRoute([], ['ROLE_HOSTESS', 'ROLE_CUSTOMER']),
        },
        {
            path: '/my-leaders',
            name: 'myLeaders',
            component: LeaderLegs,
            beforeEnter: protectedRoute([], ['ROLE_HOSTESS', 'ROLE_CUSTOMER']),
        },
        {
            path: '/my-team',
            name: 'myTeam',
            component: RisingStars,
            beforeEnter: protectedRoute([], ['ROLE_HOSTESS', 'ROLE_CUSTOMER']),
        },
        {
            path: '/new-partners',
            name: 'newPartners',
            component: NewStarters,
            beforeEnter: protectedRoute([], ['ROLE_HOSTESS', 'ROLE_CUSTOMER']),
            children: [
                {
                    path: ':id/orders',
                    name: 'newPartnersOrders',
                    component: NewStartersOrders,
                },
            ],
        },
        {
            path: '/income-summary',
            name: 'incomeSummary',
            component: IncomeSummary,
            beforeEnter: protectedRoute([], ['ROLE_HOSTESS', 'ROLE_CUSTOMER']),
            disabled: !unlockedAfterStage(1),
        },
        {
            path: '/orders',
            name: 'orders',
            component: Orders,
            beforeEnter: protectedRoute(['ROLE_USER', 'ROLE_ADMIN', 'ROLE_HOSTESS',
                'ROLE_CUSTOMER'], []),
            permissions: ['ROLE_USER', 'ROLE_ADMIN'],
            disabled: !unlockedAfterStage(1),
        },
        {
            path: '/create-event',
            name: 'createEvent',
            component: CreateEvent,
            beforeEnter: protectedRoute(['ROLE_USER', 'ROLE_ADMIN'], ['ROLE_IMPERSONATE', 'ROLE_HOSTESS', 'ROLE_CUSTOMER']),
            permissions: ['ROLE_USER', 'ROLE_ADMIN'],
            disabled: impersonating() || !unlockedAfterStage(1),
        },
        {
            path: '/create-event/type',
            name: 'createEventType',
            component: CreateEventType,
            beforeEnter: protectedRoute(['ROLE_USER', 'ROLE_ADMIN'], ['ROLE_IMPERSONATE', 'ROLE_HOSTESS', 'ROLE_CUSTOMER']),
            permissions: ['ROLE_USER', 'ROLE_ADMIN'],
            disabled: impersonating() || !unlockedAfterStage(1),
        },
        {
            path: '/create-event/hostess',
            name: 'createEventHostess',
            component: CreateEventHostess,
            children: [
                {
                    path: 'hostess-select',
                    name: 'createEventKnownHostess',
                    component: KnownHostessSelect,
                },
            ],
            beforeEnter: protectedRoute(['ROLE_USER', 'ROLE_ADMIN'], ['ROLE_IMPERSONATE', 'ROLE_HOSTESS', 'ROLE_CUSTOMER']),
            permissions: ['ROLE_USER', 'ROLE_ADMIN'],
            disabled: impersonating() || !unlockedAfterStage(1),
        },
        {
            path: '/create-event/details',
            name: 'createEventDetails',
            component: CreateEventView,
            beforeEnter: protectedRoute(['ROLE_USER', 'ROLE_ADMIN'], ['ROLE_IMPERSONATE', 'ROLE_HOSTESS', 'ROLE_CUSTOMER']),
            permissions: ['ROLE_USER', 'ROLE_ADMIN'],
            disabled: impersonating() || !unlockedAfterStage(1),
        },
        {
            path: '/edit-event/:id',
            name: 'editEvent',
            component: EditEvent,
            children: [
                {
                    path: 'hostess',
                    name: 'editEventKnownHostess',
                    component: KnownHostessSelect,
                },
            ],
            beforeEnter: protectedRoute(['ROLE_USER', 'ROLE_ADMIN', 'ROLE_HOSTESS'], ['ROLE_IMPERSONATE', 'ROLE_CUSTOMER']),
            permissions: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_HOSTESS'],
            disabled: !unlockedAfterStage(1),
        },
        {
            path: '/events/:id/view',
            name: 'eventView',
            component: EventView,
            disabled: !unlockedAfterStage(1),
        },
        {
            path: '/events/calendar',
            name: 'eventCalendar',
            component: Events,
            disabled: !unlockedAfterStage(1),
        },
        {
            path: '/send-event-invite',
            name: 'eventInvite',
            component: EventInvite,
        },
        {
            path: '/event-invitation/:id',
            name: 'eventInvitationView',
            component: EventInvitationView,
        },
        {
            path: '/invite',
            name: 'invite',
            component: Invite,
            beforeEnter: protectedRoute(['ROLE_USER', 'ROLE_ADMIN'], ['ROLE_IMPERSONATE', 'ROLE_HOSTESS', 'ROLE_CUSTOMER']),
            permissions: ['ROLE_USER', 'ROLE_ADMIN'],
            disabled: !(unlockedAfterStage(1) && !impersonating()),
        },
        {
            path: '/personal-information',
            name: 'profile',
            component: Profile,
            beforeEnter: protectedRoute(['ROLE_USER', 'ROLE_ADMIN', 'ROLE_HOSTESS', 'ROLE_CUSTOMER'], ['ROLE_IMPERSONATE']),
            permissions: ['ROLE_USER', 'ROLE_ADMIN'],
            disabled: !unlockedAfterStage(1) || impersonating(),
        },
        {
            path: '/my-customers',
            name: 'myCustomers',
            component: MyCustomers,
            children: [
                {
                    path: ':id/orders',
                    name: 'myCustomerOrders',
                    component: CustomerOrders,
                },
                {
                    path: ':id/edit',
                    name: 'editMyCustomer',
                    component: EditCustomer,
                },
            ],
            beforeEnter: protectedRoute(['ROLE_USER', 'ROLE_ADMIN'], ['ROLE_IMPERSONATE', 'ROLE_HOSTESS', 'ROLE_CUSTOMER']),
            permissions: ['ROLE_USER', 'ROLE_ADMIN'],
            disabled: !unlockedAfterStage(1) || impersonating(),
        },
        {
            path: '/help-and-support',
            name: 'helpAndSupport',
            component: HelpAndSupport,
        },
        // {
        //     path: '/become-partner',
        //     name: 'becomeAmbassador',
        //     component: BecomeAmbassador,
        //     beforeEnter: protectedRoute(['ROLE_HOSTESS', 'ROLE_CUSTOMER']),
        // },
        {
            path: '/commission-reports',
            name: 'reports',
            component: Reports,
        },
        {
            path: '/store-credit',
            name: 'storeCredit',
            component: StoreCredit,
            beforeEnter: protectedRoute([], ['ROLE_HOSTESS', 'ROLE_CUSTOMER']),
            disabled: !unlockedAfterStage(1),
        },
        {
            path: '/error',
            name: 'error',
            component: ErrorPage,
        },
        {
            path: '/no-permission',
            name: 'noPermission',
            component: NoPermission,
        },
        {
            path: '/end-impersonation',
            name: 'endImpersonation',
            component: EndImpersonation,
            beforeEnter: protectedRoute([], ['ROLE_HOSTESS', 'ROLE_CUSTOMER']),
        },
        {
            path: '/my-reports',
            name: 'myReports',
            component: MyReports,
        },
        {
            path: '/ui-components',
            name: 'uiComponents',
            component: UIComponents,
            disabled: !isDevelopmentEnvironment(),
            children: [
                {
                    path: 'action-menus',
                    name: 'uiComponentsActionMenus',
                    component: UIActionMenus,
                },
                {
                    path: 'buttons',
                    name: 'uiComponentsButtons',
                    component: UIButtons,
                },
                {
                    path: 'clipboards',
                    name: 'uiComponentsClipboards',
                    component: UIClipboards,
                },
                {
                    path: 'date-pickers',
                    name: 'uiComponentsDatePickers',
                    component: UIDatePickers,
                },
                {
                    path: 'dialogs',
                    name: 'uiComponentsDialogs',
                    component: UIDialogs,
                },
                {
                    path: 'expansion',
                    name: 'uiComponentsExpansion',
                    component: UIExpansion,
                },
                {
                    path: 'file-area',
                    name: 'uiComponentsFileArea',
                    component: UIFileArea,
                },
                {
                    path: 'files',
                    name: 'uiComponentsFiles',
                    component: UIFiles,
                },
                {
                    path: 'icon-buttons',
                    name: 'uiComponentsIconButtons',
                    component: UIIconButtons,
                },
                {
                    path: 'inline-edits',
                    name: 'uiComponentsInlineEdits',
                    component: UIInlineEdits,
                },
                {
                    path: 'link-buttons',
                    name: 'uiComponentsLinkButtons',
                    component: UILinkButtons,
                },
                {
                    path: 'loader',
                    name: 'uiComponentsLoader',
                    component: UILoader,
                },
                {
                    path: 'map',
                    name: 'uiComponentsMap',
                    component: UIMap,
                },
                {
                    path: 'pagination',
                    name: 'uiComponentsPagination',
                    component: UIPagination,
                },
                {
                    path: 'paginationHydra',
                    name: 'uiComponentsPaginationHydra',
                    component: UIPaginationHydra,
                },
                {
                    path: 'progress-bar',
                    name: 'uiComponentsProgressBar',
                    component: UIProgressBar,
                },
                {
                    path: 'progress-bar-2',
                    name: 'uiComponentsProgressBar2',
                    component: UIProgressBar2,
                },
                {
                    path: 'progress-bar-circular',
                    name: 'uiComponentsProgressBarCircular',
                    component: UIProgressBarCircular,
                },
                {
                    path: 'radio-options',
                    name: 'uiComponentsRadioOptions',
                    component: UIRadioOptions,
                },
                {
                    path: 'search-selects',
                    name: 'uiComponentsSearchSelects',
                    component: UISearchSelects,
                },
                {
                    path: 'selects',
                    name: 'uiComponentsSelects',
                    component: UISelects,
                },
                {
                    path: 'snackbars',
                    name: 'uiComponentsSnackbars',
                    component: UISnackbars,
                },
                {
                    path: 'subscene',
                    name: 'uiComponentsSubscene',
                    component: UISubScene,
                    title: 'SubScene element',
                    children: [
                        {
                            path: 'test1',
                            name: 'uiComponentsSubsceneTest1',
                        },
                        {
                            path: 'test2',
                            name: 'uiComponentsSubsceneTest2',
                        },
                        {
                            path: 'test3',
                            name: 'uiComponentsSubsceneTest3',
                        },
                    ],
                },
                {
                    path: 'tabs',
                    name: 'uiComponentsTabs',
                    component: UITabs,
                },
                {
                    path: 'tags',
                    name: 'uiComponentsTags',
                    component: UITags,
                },
                {
                    path: 'text-editors',
                    name: 'uiComponentsTextEditors',
                    component: UITextEditors,
                },
                {
                    path: 'text-fields',
                    name: 'uiComponentsTextFields',
                    component: UITextFields,
                },
                {
                    path: 'timezone-selects',
                    name: 'uiComponentsTimezoneSelects',
                    component: UITimeZoneSelects,
                },
                {
                    path: 'tooltips',
                    name: 'uiComponentsTooltips',
                    component: UITooltips,
                },
                {
                    path: 'warning-buttons',
                    name: 'uiComponentsWarningButtons',
                    component: UIWarningButtons,
                },
            ],
        },
    ];

    return routeArr;
};

export default routes;
