import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { NewStartersSearchType, NewStartersType } from '@/api/graphQL/graphNodes/types';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import {
    GetNewStartersResultType, GetNewStartersSearchResultType, NewStartersParamsType,
    NewStartersSearchParamsType,
} from '@/api/graphQL/graphNodes/GetNewStartersQuery';
import env from '@/environment';
import newStartersRepository from './services/newStartersRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'newStarters',
})
@AutoMutations
export class NewStarters extends VuexModule {
    static readonly NEW_STARTERS_TYPE = {
        personal: 'personal',
        central: 'central_team',
        organisational: 'organisational',
    }

    static readonly NEW_STARTERS_TIME_FRAME = {
        twoWeeks: '-2 weeks',
        oneMonth: '-4 weeks',
        threeMonths: '-90 days',
    }

    private starters: NewStartersType[] = [];

    private type: string = NewStarters.NEW_STARTERS_TYPE.central;

    private loaded: boolean = false;

    private loading: boolean = false;

    private loadingInBackground: boolean = false;

    private activeLegDetailsDialog: string = '';

    private expandedNewStarters: string[] = [];

    private newStartersTimeFrame: string = NewStarters.NEW_STARTERS_TIME_FRAME.threeMonths;

    private activeSponsorDetailsModal: string = '';

    private searchQuery: string = '';

    private searchLoading: boolean = false;

    private searchedLegsOptions: NewStartersSearchType[] = [];

    private searchedLegs: NewStartersSearchType[] = [];

    private minSearchLength: number = 3;

    private displaySearchResults: boolean = false;

    private count: number = 0;

    private limit: number = 20;

    private offset: number = 0;

    get currentDate() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true' ? mockedDateManager.getCurrentDate() : dateManager.getCurrentDate();
    }

    get twoWeeks(): string {
        return dateManager.getDateWithOffset(-2, this.currentDate, dateManager.getDayTimeFormat(), 'week');
    }

    get month(): string {
        return dateManager.getDateWithOffset(-1, this.currentDate, dateManager.getDayTimeFormat(), 'month');
    }

    get threeMonths(): string {
        return dateManager.getDateWithOffset(-3, this.currentDate, dateManager.getDayTimeFormat(), 'month');
    }

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setType(val: string) {
        this.type = val;
    }

    @Mutation
    public setActiveLegDetailsDialog(val: string) {
        this.activeLegDetailsDialog = val;
    }

    @Mutation
    public setNewStartersTimeFrame(val: string) {
        this.newStartersTimeFrame = val;
    }

    @Mutation
    public setStarters(val: NewStartersType[]) {
        this.starters = [...val];
    }

    @Mutation
    toggleExpandedList(val: string) {
        if (this.expandedNewStarters.find((item) => item === val)) {
            const index = this.expandedNewStarters.findIndex((item) => item === val);
            this.expandedNewStarters.splice(index, 1);
        } else {
            this.expandedNewStarters.push(val);
        }
    }

    @Mutation
    clearExpandedNewStarters() {
        this.expandedNewStarters = [];
    }

    @Mutation
    setActiveSponsorDetailsModal(val: string) {
        this.activeSponsorDetailsModal = val;
    }

    @Mutation
    setSearchLoading(val: boolean) {
        this.searchLoading = val;
    }

    @Mutation
    setSearchedLegs(val: NewStartersSearchType[]) {
        this.searchedLegs = val;
    }

    @Mutation
    setSearchedOptionsLegs(val: NewStartersSearchType[]) {
        this.searchedLegsOptions = val;
    }

    @Mutation
    setSearchQuery(val: string) {
        this.searchQuery = val;
    }

    @Mutation
    setDisplaySearchResults(val: boolean) {
        this.displaySearchResults = val;
    }

    @Mutation
    public setOffset(val:number) {
        this.offset = val;
    }

    @Mutation
    public setCount(val: number) {
        this.count = val;
    }

    @Action()
    public async getData(data?: {offset?: number, loadInBackground: boolean}) {
        const offset = data && data.offset ? data.offset : 0;
        const loadInBackground = data && data.loadInBackground ? data.loadInBackground : false;

        try {
            if (loadInBackground) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const params: NewStartersParamsType = {
                date: await this.handleTimeFrame(),
                type: this.type,
                offset,
                limit: this.limit,
            };

            const res: GetNewStartersResultType = await newStartersRepository
                .getNewStarters(params) as GetNewStartersResultType;
            const { newStartersCommissionEngine, newStartersCountCommissionEngine } = res.profile;

            this.setCount(newStartersCountCommissionEngine);

            this.setStarters(newStartersCommissionEngine);
            this.setOffset(offset);
            this.setLoaded(true);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (loadInBackground) {
                this.setLoadingInBackground(false);
            } else {
                this.setLoading(false);
            }
        }
    }

    @Action()
    async search(data: { query?: string|null, selectedSearch?: boolean }
    = { query: null, selectedSearch: false }) {
        const { query, selectedSearch } = data;

        try {
            if (selectedSearch) {
                this.setSearchLoading(true);
            }

            const params: NewStartersSearchParamsType = {
                date: await this.handleTimeFrame(),
                type: this.type,
                query: query || query === '' ? query : this.searchQuery,
            };

            const result: GetNewStartersSearchResultType = await newStartersRepository
                .searchNewStarters(params) as GetNewStartersSearchResultType;

            const { searchNewStarters } = result.profile;

            if (selectedSearch) {
                this.setSearchedLegs(searchNewStarters);
            } else {
                this.setSearchedOptionsLegs(searchNewStarters);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (selectedSearch) {
                this.setSearchLoading(false);
            }
        }
    }

    @Action()
    private handleTimeFrame() {
        let date = '';

        switch (this.newStartersTimeFrame) {
        case NewStarters.NEW_STARTERS_TIME_FRAME.twoWeeks:
            date = this.twoWeeks;
            break;

        case NewStarters.NEW_STARTERS_TIME_FRAME.oneMonth:
            date = this.month;
            break;

        case NewStarters.NEW_STARTERS_TIME_FRAME.threeMonths:
            date = this.threeMonths;
            break;

        default:
            date = this.twoWeeks;
            break;
        }
        return date;
    }
}

export default getModule(NewStarters);
