
import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import { MILESTONE_TYPE } from '@/modules/Dashboard/constants';
import WildcardManager from '@/modules/Dashboard/services/wildcardManager';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Item from './item.vue';

@Component({
    components: {
        Item,
    },
})
export default class NewStartersContentQuickStartMilestones extends Vue {
    @Prop() private isMobile!: boolean;

    @Prop() private milestones!: MilestoneType[];

    @Prop() private region!: string;

    get quickstartMilestones() {
        if (this.isGB) {
            return this.milestones.filter((item) => item.type === MILESTONE_TYPE.quickStart
            && ((item.key.includes('uk') && !item.key.includes('non_uk')) || !item.key.includes('uk')));
        }

        return this.milestones.filter((item) => item.type === MILESTONE_TYPE.quickStart
         && (item.key.includes('non_uk') || !item.key.includes('uk')));
    }

    get isGB(): boolean {
        return this.region === COUNTRY_SELECT_OPTIONS.gb;
    }

    labels(type: string) {
        return WildcardManager.quickStartLabelResolver(type, this.$t);
    }
}
