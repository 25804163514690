
import { Get } from '@/utils/vuex-module-mutators';
import { RouteType } from '@/views/Navigation/Item/types';
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import profile from '@/modules/Profile';
import auth from '@/modules/Auth';
import settings from '@/modules/Settings';
import { EventPerformanceRewardType } from '@/api/graphQL/graphNodes/types';
import { SelectOptionsType } from '../select/types';

@Component
export default class Scene extends Vue {
    @Prop({ default: '' }) private title!: string;

    @Prop({ default: true }) private withTitle!: boolean;

    @Prop({ default: false }) private withoutPadding!: boolean;

    @Prop({ default: false }) private backButton!: boolean;

    @Prop() private backRoute!: RouteType;

    @Prop({ default: false }) private alwaysActive!: boolean;

    @Get(profile) private rewards!: EventPerformanceRewardType[];

    @Get(settings) private lang!: string;

    get getClasses() {
        return [
            'Scene__Content',
            { Scene__WithoutTitleContent: !this.withTitle },
            { 'Scene__Content--noPadding': this.withoutPadding },
        ];
    }

    get getTitleContainerClasses() {
        return [
            'Scene__TitleContainer',
            { Scene__TitleSingleContainer: !this.backButton },
        ];
    }

    get isSceneActive() {
        if (!this.alwaysActive) {
            return this.$route.matched.length === 1;
        }
        return true;
    }

    get elementStyle() {
        return {
            visibility: this.isSceneActive ? 'visible' : 'hidden',
        };
    }

    get isHostess() {
        return auth.isHostess;
    }

    get langOptions(): SelectOptionsType[] {
        return settings.langOptions;
    }

    handleBackButton() {
        if (!this.backRoute) {
            this.$router.go(-1);
        } else {
            this.$router.push(this.backRoute);
        }
    }

    handleRewardButton() {
        this.$router.push({ name: 'dashboard' });
    }

    setLang(val: string) {
        settings.setLang(val);
    }
}
