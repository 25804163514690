
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import componentsControls from '@/modules/ComponentsControls';

@Component
export default class InlineEditElement extends Vue {
    @Get(componentsControls) private inlineEditActionType!: string;

    @Prop({ default: '' }) private customActionIcon!: string;

    @Prop({ default: '' }) private customActionText!: string;

    @Prop({ default: false }) private enableCustomAction!: boolean;

    @Prop({ default: false }) private enableEdit!: boolean;

    @Prop({ default: false }) private enableCreate!: boolean;

    @Prop({ default: false }) private enableRemove!: boolean;

    @Prop({ default: false }) private disableActions!: boolean;

    @Prop({ default: false }) private openDialog!: boolean;

    get submitButtonText() {
        if (this.inlineEditActionType === 'create') {
            return this.$t('ui.create');
        } if (this.inlineEditActionType === 'edit') {
            return this.$t('ui.save');
        } if (this.inlineEditActionType === 'custom') {
            return this.customActionText;
        }
        return this.$t('ui.remove');
    }

    handleOpen(type) {
        componentsControls.setInlineEditType(type);
        this.$emit('open');
        this.$emit(`open${type.charAt(0).toUpperCase() + type.slice(1)}`);
    }

    handleClose() {
        this.$emit('close');
    }

    handleSubmit() {
        this.$emit('submit');

        switch (this.inlineEditActionType) {
        case 'edit': {
            this.$emit('edit');
            break;
        }
        case 'remove': {
            this.$emit('remove');
            break;
        }
        case 'custom': {
            this.$emit('custom');
            break;
        }
        default: {
            this.$emit('create');
            break;
        }
        }
    }
}
