export const DATE_PICKER_TYPES = {
    date: 'date',
    datetime: 'datetime',
    time: 'time',
    fullDateTime: 'fullDateTime',
    timeSelect: 'timeSelect',
    yearInput: 'yearInput',
};

export const DATE_PICKER_CONFIG_DAY = 'weekDayConfig';
export const DATE_PICKER_CONFIG_SHORT_DAY = 'weekDayShortConfig';
export const DATE_PICKER_CONFIG_MONTH = 'monthConfig';
export const DATE_PICKER_CONFIG_SHORT_MONTH = 'monthShortConfig';
