import API from '@/api/graphQL';
import { GetDashboardParamsType } from '@/api/graphQL/graphNodes/GetDashboardQuery';
import { GetRankingsParamsType } from '@/api/graphQL/graphNodes/GetRankingsQuery';

export default class DashboardRepository {
    static getData(data: GetDashboardParamsType) {
        return API
            .getDashboard()
            .query(data);
    }

    static getTotalsData() {
        return API
            .getDashboardTotals()
            .query();
    }

    static getRankings(params: GetRankingsParamsType) {
        return API
            .getRankings()
            .query(params);
    }
}
