
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import risingStars, { RisingStars } from '@/modules/RisingStars';
import { Debounced } from '@/utils/debounced';
import { RisingStarsType } from '@/api/graphQL/graphNodes/types';

@Component({
    components: {},
})
export default class RisingStarsHeader extends Vue {
    @Sync(risingStars) private searchQuery!: string;

    @Get(risingStars) private type!: string;

    @Get(risingStars) private searchLoading!: boolean;

    @Get(risingStars) private minSearchLength!: number;

    @Get(risingStars) private searchedLegsOptions!: RisingStarsType[];

    @Get(risingStars) private displaySearchResults!: boolean;

    get searchOptions(): SelectOptionsType[] {
        return this.searchedLegsOptions.map((leg) => ({
            name: `${leg.firstName} ${leg.lastName}`,
            value: `${leg.firstName} ${leg.lastName}`,
        }));
    }

    get sortTypeOptions(): SelectOptionsType[] {
        return [
            {
                name: this.$t('view.risingStars.header.sortPS'),
                value: RisingStars.TYPE_TYPES.ps,
            },
            {
                name: this.$t('view.risingStars.header.sortAOF'),
                value: RisingStars.TYPE_TYPES.aof,
            },
            {
                name: this.$t('view.risingStars.header.sortBreakaway'),
                value: RisingStars.TYPE_TYPES.breakAway,
            },
        ];
    }

    get searchContainerClasses() {
        return [
            'RisingStarsHeader__Container',
            'pts-layout-item',
            'pts-size-50',
            'pts-small-size-100',
        ];
    }

    get sortContainerClasses() {
        return [
            'RisingStarsHeader__Container',
            'pts-layout-item',
            'pts-size-50',
            'pts-small-size-100',
        ];
    }

    handleSort(val) {
        risingStars.setType(val);

        if (this.searchQuery === '') {
            risingStars.getRisingStars({});
        } else {
            risingStars.search();
        }
    }

    handleSelect(val) {
        risingStars.setSearchQuery(val.value);
        risingStars.setDisplaySearchResults(true);
        risingStars.search({ selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            risingStars.setDisplaySearchResults(false);
            risingStars.setSearchedLegs([]);
            risingStars.setSearchedOptionsLegs([]);
        }
        if (this.searchQuery.length >= this.minSearchLength) { risingStars.search(); }
    }
}
