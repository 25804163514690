export default (t) => ([
    {
        label: t('common.registration.steps.stepOne'),
        value: 0,
    },
    {
        label: t('common.registration.steps.stepTwo'),
        value: 1,
    },
    {
        label: t('common.registration.steps.stepThree'),
        value: 2,
    },
]);
