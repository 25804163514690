
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CreateEventType } from '@/modules/Event/Events/types';
import { SelectOptionsType } from '@/components/select/types';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import countries from '@/modules/Countries';
import { CountriesType } from '@/modules/Event/types';
import BaseAddressSelect from '@/projectComponents/addressSelect/base.vue';
import FormFieldTooltip from '@/projectComponents/formFieldTooltip/index.vue';
import eventShippingAddressSelect from '@/modules/Event/EventShippingAddressSelect';
import eventShippingAddressSelectOption from '@/modules/Event/EventShippingAddressSelect/addressOption';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';

@Component({
    components: {
        BaseAddressSelect,
        PropertyInfoDisplay,
        FormFieldTooltip,
    },
})
export default class EventShippingDetailsForm extends Vue {
    @Get(countries) private countries!: CountriesType[];

    @Get(eventShippingAddressSelect) optionsIds!: string[];

    @Sync(eventShippingAddressSelect) private searchWord!: string;

    @Get(eventShippingAddressSelect) private loading!: boolean;

    @Get(eventShippingAddressSelect) private resultSize!: number;

    @Prop() private value!: CreateEventType;

    @Prop() private formErrors!: any;

    @Prop({ default: false }) private displayCurrentAddress!: boolean;

    @Prop({ default: false }) eventEditView!: boolean;

    get options(): SelectOptionsType[] {
        return this.optionsIds.map((item) => {
            const { data, id } = eventShippingAddressSelectOption.collection[item];
            const description = data!.description !== '' ? `, ${data!.description}` : '';

            return {
                name: `${data!.text}${description}`,
                value: id!,
            };
        });
    }

    get address(): string {
        const {
            address, secondAddress, postcode,
            city, county, country,
        } = this.value.shippingAddress;

        let res = address;

        if (!!secondAddress && secondAddress !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${secondAddress}`;
        }

        if (!!city && city !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${city}`;
        }

        if (!!postcode && postcode !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${postcode}`;
        }

        if (!!county && county !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${county}`;
        }

        if (!!country && country !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${country}`;
        }

        return res;
    }

    get countryOptions():SelectOptionsType[] {
        return this.countries.map((country) => ({
            name: country.longLabel,
            value: country.shortLabel,
        }));
    }

    handleValue(value: string, name: string) {
        const val = this.value.shippingAddress;

        val[name] = value;

        const res = { key: 'shippingAddress', val };

        this.$emit('input', res);
    }

    setLabel(val: SelectOptionsType) {
        eventShippingAddressSelect.setAddress(String(val.value));
        eventShippingAddressSelect.setSearchWord(val.name);
    }

    loadOptions() {
        eventShippingAddressSelect.findAddresses();
    }
}
