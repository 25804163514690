import {
    Mutation, Module, getModule, VuexModule,
} from 'vuex-module-decorators';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { ErrorType, ObjectProcessor, CustomAction as Action } from '@plumtreesystems/utils';
import { ObjectPropertyType, ValidationErrorType } from '@/modules/types';
import {
    AgreementType, EventViewType, InvitationType,
} from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { initialEventInvitationData, initialInviteData } from './defaults';
import { FORM_ERROR_TOOLTIP_TIME_INTERVAL } from '../../constants';
import { EventInviteType } from './types';
import { eventInviteFormValidation } from '../services/eventInviteFormValidation';
import { initialEventViewData } from '../Events/defaults';
import EventRepository from '../Events/services/eventRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'eventInvitation',
})
@AutoMutations
export class EventInvitation extends VuexModule {
    private inviteFormData: EventInviteType = initialInviteData();

    private inviteFormErrors: ErrorType = {};

    private loading: boolean = false;

    private invitationData: InvitationType = initialEventInvitationData();

    private showQrCode: boolean = false;

    private eventData: EventViewType = initialEventViewData();

    private responseSending: boolean = false;

    private inviteSent: boolean = false;

    private shadowEnrollee: boolean = false;

    private displayTooltip: boolean = false;

    private tooltipError: string = '';

    private declineModalOpen: boolean = false;

    private roles: string[] = [];

    private agreements: AgreementType[] = [];

    @Mutation
    setDisplayTooltip(val: boolean) {
        this.displayTooltip = val;
    }

    @Mutation
    setInviteFormError(payload: ObjectPropertyType) {
        const { key, val } = payload;
        this.inviteFormErrors = ObjectProcessor
            .setPropertyByValue(key, val, this.inviteFormErrors);
    }

    @Mutation
    clearInviteFormErrors() {
        this.inviteFormErrors = {};
    }

    @Mutation
    setInviteFormErrors(errors: any) {
        this.inviteFormErrors = { ...errors };
    }

    @Mutation
    setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    setResponseSending(val: boolean) {
        this.responseSending = val;
    }

    @Mutation
    public toggleQrCode() {
        this.showQrCode = !this.showQrCode;
    }

    @Mutation
    setInvitationData(data: InvitationType) {
        this.invitationData = { ...this.invitationData, ...data };
    }

    @Mutation
    clearInvitationData() {
        this.invitationData = { ...initialEventInvitationData() };
    }

    @Mutation
    setEventData(data: EventViewType) {
        this.eventData = { ...data };
    }

    @Mutation
    clearEventData() {
        this.eventData = { ...initialEventViewData() };
    }

    @Mutation
    public clearInviteFormData() {
        this.inviteFormData = { ...initialInviteData() };
    }

    @Mutation
    setInviteSent(val: boolean) {
        this.inviteSent = val;
    }

    @Mutation
    setShadowEnrollee(val: boolean) {
        this.shadowEnrollee = val;
    }

    @Mutation
    setDeclineModalOpen(val: boolean) {
        this.declineModalOpen = val;
    }

    @Mutation
    setTooltipError(val: string) {
        this.tooltipError = val;
    }

    @Mutation
    setRoles(val: string[] = []) {
        this.roles = [...val];
    }

    @Mutation
    public setAgreements(val: AgreementType[] = []) {
        this.agreements = [...val];
    }

    get isHostess(): boolean {
        return this.roles.includes('ROLE_HOSTESS');
    }

    get isCustomer(): boolean {
        return this.roles.includes('ROLE_CUSTOMER');
    }

    @Action()
    public displayFormErrorsTooltip(message: string = '') {
        if (message !== '') {
            this.setTooltipError(message);
        }
        this.setDisplayTooltip(true);
        setTimeout(() => {
            this.setDisplayTooltip(false);
            this.setTooltipError('');
        }, FORM_ERROR_TOOLTIP_TIME_INTERVAL);
    }

    @Action()
    public async handleInviteSend(token: string) {
        this.validateInviteForm();

        if (Object.keys(this.inviteFormErrors).length === 0) {
            this.setLoading(true);

            try {
                await EventRepository.customerEventInvite(token, { ...this.inviteFormData });
                this.setInviteSent(true);
            } catch (e) {
                const errors = ErrorsProcessor.process(e);
                this.setInviteFormErrors(errors.form);
                throw e;
            } finally {
                this.setLoading(false);
            }
        } else {
            // @ts-ignore
            const message = this.store._vm.$t('validation.invalidForm');
            this.displayFormErrorsTooltip(message);
            throw new Error(message);
        }
    }

    @Action()
    public async getEventInvitation(payload: { id: string; token: string }) {
        this.setLoading(true);

        try {
            const { id, token } = payload;
            const result = await EventRepository.getEventInvitation(id, token);
            this.setShadowEnrollee(result.profile.shadow);
            this.setAgreements(result.profile.agreements);
            this.setRoles(result.me.roles);
            this.setEventData(result.event);
            this.setInvitationData(result.invitationForEvent);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async respondToInvitation(payload: {
        attending: string;
        token: string;
        hostessInvitation: boolean;
    }) {
        this.setResponseSending(true);
        try {
            const { attending, token, hostessInvitation } = payload;
            const result = await EventRepository.respondToInvitation({
                eventId: this.eventData.id,
                attending,
            }, token);
            this.setInvitationData(result.respondToInvitation);

            if (hostessInvitation) {
                // event status
                const confirmationRes = await EventRepository
                    .getPublicEventConfirmation({ id: this.eventData.id }, token);

                this.setEventData({
                    ...this.eventData,
                    confirmed: confirmationRes.event.confirmed,
                });
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setResponseSending(false);
        }
    }

    @Action()
    private validateInviteForm() {
        const { inviteFormData } = this;
        this.clearInviteFormErrors();

        if (!inviteFormData.firstName || !inviteFormData.lastName || !inviteFormData.email) {
            this.displayFormErrorsTooltip();
        }

        // @ts-ignore
        const formErrors = eventInviteFormValidation(inviteFormData, this.store._vm.$t);
        formErrors.forEach((error: ValidationErrorType) => {
            this.setInviteFormError({ key: error.key, val: error.val });
        });
    }
}

export default getModule(EventInvitation);
