
import { Vue, Component } from 'vue-property-decorator';
import eventEdit from '@/modules/Event/EventEdit';
import countries from '@/modules/Countries';
import { Get } from '@/utils/vuex-module-mutators';
import EventEditForm from '@/views/Events/Components/EventForm/index.vue';
import { ObjectPropertyType } from '@/modules/types';
import { EventType } from '@/api/graphQL/graphNodes/types';
import { ErrorType } from '@plumtreesystems/utils';
import auth from '@/modules/Auth';
import addressSelect from '@/modules/AddressSelect';
import componentsControls from '@/modules/ComponentsControls';
import { descriptionLengthValidation } from '@/modules/Event/services/fieldUpdateValidations';
import HostessDisplay from './hostess/index.vue';

@Component({
    components: {
        EventEditForm,
        HostessDisplay,
    },
})
export default class EditEvent extends Vue {
    @Get(auth) private suspended!: boolean;

    @Get(eventEdit) private loading!: boolean;

    @Get(eventEdit) private eventData!: EventType;

    @Get(eventEdit) private formErrors!: ErrorType;

    @Get(countries, 'loading') private countriesLoading!: boolean;

    get isLoading(): boolean {
        return this.loading || this.countriesLoading;
    }

    beforeMount() {
        addressSelect.resetData();
        countries.getCountries();
        eventEdit.getEvent(this.$route.params.id);
    }

    handleInput(value: ObjectPropertyType) {
        const { key, val } = value;
        if (key === 'description') {
            const error = descriptionLengthValidation(val, this.$t);

            if (error) {
                eventEdit.setEventFormError({
                    key,
                    val: error,
                });
            } else {
                eventEdit.removeEventFormError(key);
            }
        }

        eventEdit.setEventProperty(value);
    }

    async handleSave() {
        try {
            await eventEdit.updateEvent();
            if (Object.keys(this.formErrors).length === 0) {
                this.$router.push({ name: 'eventView', params: { id: this.$route.params.id } });
                componentsControls.showSuccessMessage({ message: this.$t('view.event.message.updated') });
            }
            // eslint-disable-next-line no-empty
        } finally {}
    }
}
