import { LocalStorageManager } from '@plumtreesystems/utils';

const storeVersion: string = '1.1.2';

const excludedModules: string[] = [
    'componentsControls',
    'componentsSandbox',
    'componentsSandboxExpandableItem',
    'system',
    'impersonationControls',
    'eventInvitation',
    'addressSelectOptions',
    'addressSelect',
    'profileBusinessAddressSelectOptions',
    'profileBusinessAddressSelect',
    'eventShippingAddressSelectOptions',
    'eventShippingAddressSelect',
];

export default new LocalStorageManager(storeVersion, excludedModules);
