
import { Vue, Component } from 'vue-property-decorator';
import newStarters from '@/modules/NewStarters';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import { NewStartersSearchType } from '@/api/graphQL/graphNodes/types';
import { Debounced } from '@/utils/debounced';

@Component({
    components: {},
})
export default class NewStartersHeader extends Vue {
    @Sync(newStarters) private searchQuery!: string;

    @Get(newStarters) private searchLoading!: boolean;

    @Get(newStarters) private minSearchLength!: number;

    @Get(newStarters) private searchedLegsOptions!: NewStartersSearchType[];

    @Get(newStarters) private displaySearchResults!: boolean;

    @Get(newStarters) private displaySearch!: boolean;

    get searchOptions(): SelectOptionsType[] {
        return this.searchedLegsOptions.map((leg) => ({
            name: `${leg.firstName} ${leg.lastName}`,
            value: `${leg.firstName} ${leg.lastName}`,
        }));
    }

    get searchContainerClasses() {
        return [
            'pts-layout-item',
            'pts-size-100',
            'pts-small-size-100',
            'NewStartersHeader__Container',
        ];
    }

    handleSelect(val) {
        newStarters.setSearchQuery(val.value);
        newStarters.setDisplaySearchResults(true);
        newStarters.search({ selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            newStarters.setDisplaySearchResults(false);
            newStarters.setSearchedOptionsLegs([]);
            newStarters.setSearchedLegs([]);
        }
        if (this.searchQuery.length >= this.minSearchLength) { newStarters.search(); }
    }
}
