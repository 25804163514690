import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { ResendInviteParamsType } from '@/api/rest/Resources/types';
import componentsControl from '@/modules/ComponentsControls';
import PublicRepository from './services/publicRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'afterRegistration',
})
@AutoMutations
export class AfterRegistration extends VuexModule {
    private email: string = 'test+2@plumtreesystems.com';

    @Mutation
    public setEmail(val: string) {
        this.email = val;
    }

    @Action()
    public async resendRegistration(captcha: string = '') {
        try {
            const data: ResendInviteParamsType = {
                captcha, email: this.email,
            };

            await PublicRepository.publicResendInvite(data);
            // @ts-ignore
            componentsControl.showSuccessMessage({ message: this.store._vm.$t('message.invitationResent') });
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        }
    }
}

export default getModule(AfterRegistration);
