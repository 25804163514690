// import { CurrencyPrefix } from '@plumtreesystems/utils';
// import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

// export default new CurrencyPrefix(COUNTRY_SELECT_OPTIONS);

class CurrencyPrefix {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public resolvePrefix(_) {
        return '€';
    }
}

export default new CurrencyPrefix();
