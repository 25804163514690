<template>
    <div :aria-hidden="true">
        <div class="BusinessShape__LegendItem">
            <div class="BusinessShape__LegendShape BusinessShape__LegendShape--active"></div>
            <span>{{ $t('view.businessShape.legend.activeTitle')}}</span>
            <div>
                <Icon icon="info" />
                <md-tooltip>
                    {{ $t('view.businessShape.legend.activeTooltip')}}
                </md-tooltip>
            </div>
        </div>
        <div class="BusinessShape__LegendItem">
            <div class="BusinessShape__LegendShape BusinessShape__LegendShape--activeOnFile"></div>
            <span>{{ $t('view.businessShape.legend.activeOnFileTitle')}}</span>
            <div>
                <Icon icon="info" />
                <md-tooltip>
                    {{ $t('view.businessShape.legend.activeOnFileTooltip')}}
                </md-tooltip>
            </div>
        </div>
        <div class="BusinessShape__LegendItem">
            <div class="BusinessShape__LegendShape BusinessShape__LegendShape--notActive"></div>
            <span>{{ $t('view.businessShape.legend.notActiveTitle')}}</span>
            <div>
                <Icon icon="info" />
                <md-tooltip>
                    {{ $t('view.businessShape.legend.notActiveTooltip')}}
                </md-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BusinessShapeLegend',
};
</script>

<style lang="scss">
    @import './styles.scss';
</style>
