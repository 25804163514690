import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import {
    GetRisingStarsResultType, GetRisingStarsSearchResultType, RisingStarsParamsType,
    RisingStarsSearchParamsType,
} from '@/api/graphQL/graphNodes/GetRisingStarsQuery';
import { RisingStarsType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import RisingStarsRepository from './services/risingStarsRepository';
import legModule, { Leg } from './leg';

@Module({
    namespaced: true, dynamic: true, store, name: 'risingStars',
})
@AutoMutations
export class RisingStars extends VuexModule {
    static readonly SORT_TYPES = {
        ps: 'null',
        rank: 'qualified_personal_legs',
    }

    static readonly TYPE_TYPES = {
        ps: 'personal_sales',
        breakAway: 'breakaway_percent',
        aof: 'aof_worst',
    }

    private legs: RisingStarsType[] = [];

    private loaded: boolean = false;

    private loading: boolean = false;

    private activeLegDetailsDialog: string = '';

    private expandedRisingStars: string[] = [];

    private loadingInBackground: boolean = false;

    private legsCount: number = 0;

    private limit: number = 20;

    private offset: number = 0;

    private activeSponsorDetailsModal: string = '';

    private sortType: string = RisingStars.SORT_TYPES.ps;

    private type: string = RisingStars.TYPE_TYPES.ps;

    private searchQuery: string = '';

    private searchLoading: boolean = false;

    private searchedLegsOptions: RisingStarsType[] = [];

    private searchedLegs: RisingStarsType[] = [];

    private minSearchLength: number = 3;

    private displaySearchResults: boolean = false;

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setData(val: RisingStarsType[]) {
        this.legs = [...val];
    }

    @Mutation
    public addData(val: RisingStarsType[]) {
        this.legs = [...this.legs, ...val];
    }

    @Mutation
    public setActiveLegDetailsDialog(val: string) {
        this.activeLegDetailsDialog = val;
    }

    @Mutation
    toggleExpandedList(val: string) {
        if (this.expandedRisingStars.find((item) => item === val)) {
            const index = this.expandedRisingStars.findIndex((item) => item === val);
            this.expandedRisingStars.splice(index, 1);
        } else {
            this.expandedRisingStars.push(val);
        }
    }

    @Mutation
    clearExpandedList() {
        this.expandedRisingStars = [];
    }

    @Mutation
    setLegsCount(val) {
        this.legsCount = val;
    }

    @Mutation
    setOffset(val: number) {
        this.offset = val;
    }

    @Mutation
    setActiveSponsorDetailsModal(val: string) {
        this.activeSponsorDetailsModal = val;
    }

    @Mutation
    setSortType(val:string) {
        this.sortType = val;
    }

    @Mutation
    setType(val:string) {
        this.type = val;
    }

    @Mutation
    setSearchLoading(val: boolean) {
        this.searchLoading = val;
    }

    @Mutation
    setSearchedLegs(val: RisingStarsType[]) {
        this.searchedLegs = val;
    }

    @Mutation
    setSearchedOptionsLegs(val: RisingStarsType[]) {
        this.searchedLegsOptions = val;
    }

    @Mutation
    setSearchQuery(val: string) {
        this.searchQuery = val;
    }

    @Mutation
    setDisplaySearchResults(val: boolean) {
        this.displaySearchResults = val;
    }

    @Action()
    public async getRisingStars(data: {loadPage?: boolean, offset?: number }) {
        const { loadPage = false, offset = 0 } = data;

        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const params: RisingStarsParamsType = {
                limit: this.limit,
                offset,
                sort: this.sortType === 'null' ? '' : this.sortType,
                type: this.type,
            };

            const result = await RisingStarsRepository
                .getRisingStars(params) as GetRisingStarsResultType;

            const {
                risingStars, risingStarsCount,
            } = result.profile;

            legModule.setElements([]);
            risingStars.forEach((star) => {
                this.handleLegCreate(star);
            });

            this.setLegsCount(risingStarsCount);

            this.setData(risingStars);
            this.setOffset(offset);

            if (!loadPage) {
                this.setLoaded(true);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoadingInBackground(false);
            this.setLoading(false);
        }
    }

    @Action()
    async search(data: { query?: string|null, selectedSearch?: boolean }
    = { query: null, selectedSearch: false }) {
        const { query, selectedSearch } = data;

        try {
            if (selectedSearch) {
                this.setSearchLoading(true);
            }

            const params: RisingStarsSearchParamsType = {
                query: query || query === '' ? query : this.searchQuery,
                type: this.type,
            };

            const result: GetRisingStarsSearchResultType = await RisingStarsRepository
                .searchRisingStars(params) as GetRisingStarsSearchResultType;

            const { searchRisingStars } = result.profile;

            if (selectedSearch) {
                searchRisingStars.forEach((star) => {
                    if (!legModule.collection[star.id] && selectedSearch) {
                        this.handleLegCreate(star);
                    }
                });

                this.setSearchedLegs(searchRisingStars);
            } else {
                this.setSearchedOptionsLegs(searchRisingStars);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (selectedSearch) {
                this.setSearchLoading(false);
            }
        }
    }

    @Action()
    handleLegCreate(star: RisingStarsType) {
        const leg = new Leg();
        leg.id = star.id;
        leg.data = { ...leg.data, ...star };

        legModule.addElement(leg);
        legModule.loadData(star.id);
    }
}

export default getModule(RisingStars);
