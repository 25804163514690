import { LeaderLegsType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetLeaderLegsQuery extends
    AbstractQueryResource<GetLeaderLegsResultType|GetLeaderLegsSearchResultType
        |GetLevelsLeaderLegsSearchResultType> {
    protected getQuery(options = { type: '' }): string {
        const { type } = options;

        let parameters = '';
        let query = '';
        let pickFrom = '';

        if (type === '') {
            parameters = '$limit: Int!, $offset: Int!';
            query = 'leaderLegs(limit: $limit, offset: $offset)';
            pickFrom = 'profile';
        } else if (type === 'levels') {
            parameters = '$limit: Int!, $offset: Int!, $id: String!';
            query = 'leaderLegs(limit: $limit, offset: $offset)';
            pickFrom = 'enrollee(id: $id)';
        } else {
            parameters = '$query: String!';
            query = 'searchLeaderLegs(query: $query)';
            pickFrom = 'profile';
        }

        return `query ${this.getName()}(${parameters}) {
            ${pickFrom} {
                ${query} {
                    activeGroupEnrolleesCount,
                    id,
                    totals {
                        pv,
                    },
                    firstName,
                    lastName,
                    rank {
                        id,
                        label,
                        power,
                    },
                    payRank {
                        id,
                        label,
                        power,
                    },
                },
                ${type === '' ? 'qualifiedLeaderBranchIds,' : ''}
                ${type === '' ? 'leaderLegsCount,' : ''}
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getLeaderLegsList';
    }
}

export type GetLeaderLegsResultType = {
    profile: {
        leaderLegs: LeaderLegsType[];
        qualifiedLeaderBranchIds: string[];
        leaderLegsCount: number;
    }
}

export type GetLeaderLegsSearchResultType = {
    profile: {
        searchLeaderLegs: LeaderLegsType[];
    }
}

export type GetLevelsLeaderLegsSearchResultType = {
    enrollee: {
        leaderLegs: LeaderLegsType[];
    }
}

export type LeaderLegsParamsType = {
    id?: string;
    limit: number;
    offset: number;
}

export type LeaderLegsSearchParamsType = {
    query: string;
}
