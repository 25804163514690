

import { Get } from '@/utils/vuex-module-mutators';
import { Component, Vue, Prop } from 'vue-property-decorator';
import myCustomers from '@/modules/MyCustomers';

@Component
export default class DirectContainer extends Vue {
    @Get(myCustomers) private direct!: boolean;

    @Prop() private isLoading!: boolean;

    handleClick(value: boolean) {
        myCustomers.setDirect(value);
        this.$emit('select');
    }
}
