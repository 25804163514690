import { CountriesType } from '@/modules/Event/types';

const countries: () => CountriesType[] = () => ([
    {
        shortLabel: 'FR',
        longLabel: 'Frankreich',
    },
    {
        shortLabel: 'DE',
        longLabel: 'Deutschland',
    },
    {
        shortLabel: 'IT',
        longLabel: 'Italien',
    },
    {
        shortLabel: 'ESP',
        longLabel: 'Spanien',
    },
    {
        shortLabel: 'PL',
        longLabel: 'Polen',
    },
    {
        shortLabel: 'RO',
        longLabel: 'Rumänien',
    },
    {
        shortLabel: 'SK',
        longLabel: 'Slowakei',
    },
    {
        shortLabel: 'GB',
        longLabel: 'Vereinigtes Königreich',
    },
]);

const regions: () => CountriesType[] = () => ([
    {
        shortLabel: 'FR',
        longLabel: 'Frankreich',
    },
    {
        shortLabel: 'DE',
        longLabel: 'Deutschland',
    },
    {
        shortLabel: 'IT',
        longLabel: 'Italien',
    },
    {
        shortLabel: 'ESP',
        longLabel: 'Spanien',
    },
    {
        shortLabel: 'PL',
        longLabel: 'Polen',
    },
    {
        shortLabel: 'RO',
        longLabel: 'Rumänien',
    },
    {
        shortLabel: 'SK',
        longLabel: 'Slowakei',
    },
    {
        shortLabel: 'UK',
        longLabel: 'Vereinigtes Königreich',
    },
]);

export { countries, regions };
