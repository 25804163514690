export const COUNTRY_SELECT_OPTIONS = {
    gb: 'UK',
    ie: 'IE',
    ci: 'CI',
    gg: 'GG',
    fr: 'FR',
    de: 'DE',
    im: 'IM',
    nir: 'NIR',
    roi: 'ROI',
    it: 'IT',
    esp: 'ESP',
    pl: 'PL',
    ro: 'RO',
    sk: 'SK',
};

export const FORM_ERROR_TOOLTIP_TIME_INTERVAL = 4000;
export const BUTTON_DELAY_TIME: number = 150;
export const EXPANSION_DELAY_TIME: number = 350;
