export default class RankRequirementManager {
    static readonly rankRequirementMetricConstants = {
        starterKit: 'starterKit',
        // personalRecruits: 'qualified_personal_legs',
        personalRecruits: 'qualified_group_branch_legs',
        qualifiedBranches: 'qualified_branches',
        pv: 'pv',
        gv: 'gv',
        uv: 'uv',
        ov: 'ov',
        leaderLegs: 'qualified_leader_branches',
        ambassadorLegs: 'qualified_group_legs',
        qualifiedLeaders: 'qualified_leaders',
        // activeOnFilePartners: 'active_on_file_direct_ambassadors',
        activeOnFilePartners: 'qualified_group_branch_active_on_file_legs',
        qualifiedFrontlineLeaders: 'qualified_frontline_leaders',
    }

    public static getRankRequirementLabel(val: string, t, amount: number = 0) {
        switch (val) {
        case RankRequirementManager.rankRequirementMetricConstants.starterKit: {
            return {
                title: t('utils.rankRequirementManager.starterKit'),
                withValue: false,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.personalRecruits: {
            return {
                title: t('utils.rankRequirementManager.personalRecruits'),
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.qualifiedBranches: {
            return {
                title: t('utils.rankRequirementManager.qualifiedBranches'),
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.activeOnFilePartners: {
            return {
                title: t('utils.rankRequirementManager.activeOnFilePartners'),
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.qualifiedFrontlineLeaders: {
            return {
                title: t('utils.rankRequirementManager.qualifiedFrontlineLeaders'),
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.pv: {
            return {
                title: t('utils.rankRequirementManager.pv'),
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.gv: {
            return {
                title: t('utils.rankRequirementManager.gv'),
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.uv: {
            return {
                title: t('utils.rankRequirementManager.uv'),
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.ov: {
            return {
                title: t('utils.rankRequirementManager.ov'),
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.leaderLegs: {
            return {
                title: t('utils.rankRequirementManager.leaderLegs'),
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.ambassadorLegs: {
            return {
                title: t('utils.rankRequirementManager.ambassadorLegs', { count: amount }),
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.qualifiedLeaders: {
            return {
                title: t('utils.rankRequirementManager.qualifiedLeaders'),
                withValue: true,
            };
        }
        default: {
            return {
                title: '',
                withValue: false,
            };
        }
        }
    }
}
