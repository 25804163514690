
import { AssetType } from '@/api/graphQL/graphNodes/types';
import { defaultAsset } from '@/modules/defaults';
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import { FileType } from './types';

@Component
export default class fileUploadArea extends Vue {
    @Prop({ default: '' }) private name!: string;

    @Prop({ required: true }) private id!: string;

    @Prop({ required: true }) private value!: FileType[]|null;

    @Prop({ default: () => () => null }) private downloadNameGenerator!: (arg1) => string|null

    @Prop({ default: null }) selectedCategory!: string|null;

    @Prop({ default: true }) private multiple!: boolean;

    @Prop({ default: '' }) private placeholderTitle!: string;

    @Prop({ default: '' }) private placeholderSubtitle!: string;

    @Prop({ required: false, default: false }) private disableRemove!: boolean;

    private imageDroppingClass: boolean = false;

    get elementClasses() {
        return [
            'FileArea__Label',
            this.imageDroppingClass ? 'FileArea__Label--imageDropping' : '',
        ];
    }

    get displayPlaceholder(): boolean {
        return this.calculateFilesToDisplay === null
        || this.calculateFilesToDisplay.length === 0 || this.imageDroppingClass;
    }

    get calculateFilesToDisplay(): FileType[]|null {
        if (this.selectedCategory === null || this.value === null) {
            return this.value;
        }

        return this.value.filter((file) => file.category === this.selectedCategory);
    }

    get displayFiles(): AssetType[] {
        if (this.calculateFilesToDisplay === null) {
            return [];
        }

        return this.calculateFilesToDisplay.map((file) => {
            const res: AssetType = {
                ...defaultAsset(),
                title: file.file.name,
                originalFileName: file.file.name,
                context: [{
                    metric: 'Content-Type',
                    value: file.file.type,
                }],
            };

            return res;
        });
    }

    get placeholderTitleResolved(): string {
        const { placeholderTitle } = this;
        return placeholderTitle === '' ? this.$t('ui.fileArea.placeholderTitle') : placeholderTitle;
    }

    get placeholderSubtitleResolved(): string {
        const { placeholderSubtitle } = this;
        return placeholderSubtitle === '' ? this.$t('ui.fileArea.placeholderSubtitle') : placeholderSubtitle;
    }

    handleFilesUpload() {
        const fileInput: HTMLInputElement = this.$refs.filesInput as HTMLInputElement;

        const { files } = fileInput;
        this.handleFiles(files);
    }

    handleAreaClick() {
        const fileInput: HTMLInputElement = this.$refs.filesInput as HTMLInputElement;
        fileInput.click();
    }

    handleDrop(event) {
        const dt = event.dataTransfer;
        const { files } = dt;
        this.handleFiles(files);
    }

    handleFiles(files: FileList|null) {
        if (files && files.length > 0) {
            const result = Array.from(files).map((file) => ({
                category: this.selectedCategory,
                file,
            }));
            const filesList = this.value === null ? [...result] : [...this.value, ...result];

            this.$emit('fileAdd', result);
            this.submitChange(filesList);
        }
    }

    addImageDropping() {
        this.imageDroppingClass = true;
    }

    removeImageDropping() {
        this.imageDroppingClass = false;
    }

    submitChange(val: FileType[]|null) {
        this.$emit('input', val);
    }

    removeFileFromList(index: number) {
        if (this.value === null) {
            this.submitChange(null);
            this.$emit('fileRemove', null);
        } else {
            const data = [...this.value];

            this.$emit('fileRemove', data[index]);

            data.splice(index, 1);

            this.submitChange(data);
        }
    }
}
