
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import newStartersOrders from '@/modules/NewStarters/orders';
import system from '@/modules/System';
import profile from '@/modules/Profile';
import Skeleton from '@/projectComponents/skeleton/List/index.vue';
import { EnrolleeCustomerOrderType, BaseOrderType } from '@/api/graphQL/graphNodes/types';
import OrdersList from '@/projectComponents/OrdersList/index.vue';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import Header from './Header/index.vue';

@Component({ components: { OrdersList, Header, Skeleton } })
export default class NewPartnersOrders extends Vue {
    @Get(newStartersOrders) private orders!: EnrolleeCustomerOrderType;

    @Get(newStartersOrders) private loading!: boolean;

    @Get(newStartersOrders) private offset!: number;

    @Get(newStartersOrders) private limit!: number;

    @Get(newStartersOrders) private searchedOrders!: BaseOrderType[];

    @Get(newStartersOrders) private total!: number;

    @Get(newStartersOrders) private loadingInBackground!: number;

    @Get(newStartersOrders) private expandedOrders!: string[];

    @Get(newStartersOrders) private displaySearchResults!: boolean;

    @Get(newStartersOrders) private searchLoading!: boolean;

    @Get(newStartersOrders) private fullName!: string;

    @Get(profile, 'data.region') private region!: string;

    @Get(system) private screenType!: string;

    get title() {
        return this.$t('view.newStarters.enrolleeOrders', {
            fullName: this.fullName,
        });
    }

    get contentLoaded(): boolean {
        return this.loading || this.searchLoading;
    }

    get displayOrders(): BaseOrderType[] {
        if (this.displaySearchResults) {
            return this.searchedOrders;
        }
        return this.orders.searchOrders;
    }

    get enroleeId() {
        const { id } = this.$route.params;
        return id;
    }

    get isMobile() {
        return this.screenType === 'mobile';
    }

    get customerFullName(): string {
        if (this.orders) {
            return `${this.orders.firstName} ${this.orders.lastName}`;
        }
        return '';
    }

    handleClose() {
        newStartersOrders.setOffset();
    }

    handleSearchToggle() {
        newStartersOrders.toggleShowSearch();
    }

    handleOpen() {
        const { id } = this.$route.params;
        newStartersOrders.getOrders({ enrolleeId: id, offset: this.offset });
    }

    handleOrderOpenToggle(id: string) {
        newStartersOrders.toggleExpandedOrdersList(id);
    }

    async handlePagination(params: PaginationParamsType) {
        try {
            const { offset } = params;
            const { id } = this.$route.params;
            await newStartersOrders.getOrders({ enrolleeId: id, loadPage: true, offset });
            scrollToTop('#app > div.MyCustomers__Scene.App__Layout > div.SubScene__Container.SubScene__Container--open > div.SubScene__Content.SubScene__Content--noPadding');
        // eslint-disable-next-line no-empty
        } finally {}
    }

    beforeDestroy() {
        newStartersOrders.setSearchedOrders([]);
        newStartersOrders.setSearchedOrdersOptions([]);
        newStartersOrders.setOrdersData();
        newStartersOrders.clearExpandedOrdersList();
        newStartersOrders.setDisplaySearchResults(false);
        newStartersOrders.setSearchQuery('');
    }
}
